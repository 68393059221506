<template>
  <div class="w-full min-h-screen flex flex-col">
      <MainNav />
      <section class="w-full relative">
        <img 
                src="/image/83islands_sugarcane.jpg" 
                alt="83 Islands Jungle" 
                class="w-full h-[800px] object-cover object-[bottom_center] md:object-center"
            />
        
        <!-- Text Overlay -->
        <div class="mx-auto absolute mt-36 inset-0 z-20 w-full p-4 lg:p-36 flex flex-col items-center lg:items-start pointer-events-none">
            <h1 class="text-white font-maax text-4xl uppercase lg:text-6xl font-bold text-center lg:text-left max-w-3xl leading-tight drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] pointer-events-auto">
              Where nature shapes the spirit, and craftsmanship perfects it.
            </h1>
        </div>

<!--  Double Wave -->
            <div class="absolute -bottom-1 left-0 right-0 w-full overflow-hidden">
                <svg viewBox="0 0 1200 120" preserveAspectRatio="none" class="w-full h-20 text-white">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                          fill="currentColor" class="opacity-25"/>
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                          fill="currentColor"/>
                </svg>
            </div>

        </section>
      <main class="flex-grow w-full bg-white">
        <div class="container mx-auto p-4">
          <div class="flex flex-col lg:flex-row gap-8">
            <div class="w-full lg:w-2/3">
              <h2 class="text-4xl font-maax font-bold uppercase text-black pb-4">The Spirits of 83 Islands</h2>

              <p class="font-maax text-black text-2xl pb-8">
                Born from Vanuatu's untouched sugarcane and aged to perfection in the heart of the Pacific, our rums capture the raw, untamed essence of the islands. Every bottle tells a story of place, tradition, and craft—a testament to the pure spirit of Vanuatu.
              </p>

              <p class="font-maax text-black text-xl pb-8">
                At 83 Islands Distillery, we don't just make rum—we craft a spirit that captures the wild essence of Vanuatu. Our journey begins in the ancient volcanic soils of Efate, where sugarcane, nurtured by pure rainwater and ocean breezes, grows untamed. Working alongside over 150 local farmers, we preserve the deep-rooted traditions of cultivation while pushing the boundaries of innovation.
              </p>

              <p class="font-maax text-black text-xl pb-8">
                Every drop of our rum is shaped by mastery. In our custom-designed pot stills, we extract the purest expression of Vanuatu's sugarcane—concentrating bold aromatics and deep, complex flavors that speak of the land itself. Aged in the relentless tropical climate, our rums develop richness and depth in ways no other spirits can, absorbing the heat, humidity, and ocean air that define this untamed paradise.
              </p>

              <p class="font-maax text-black text-xl pb-8">
                From pure cane spirits that express the raw vitality of fresh sugarcane to carefully aged expressions that showcase the transformative power of time and oak, each bottle embodies our commitment to craft and our connection to these islands. This is more than rum—it's liquid history, a celebration of Vanuatu's heritage and its promising future.
              </p>

              <!-- Image: Distillery Overview -->
              <div class="relative w-full h-[400px] mb-12 rounded-lg overflow-hidden">
                <img 
                  src="/image/productionfloor.jpg" 
                  alt="83 Islands Distillery" 
                  class="w-full h-full object-cover"
                />
                <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 to-transparent p-4">
                  <p class="text-white text-sm">Our distillery in Port Vila, Vanuatu</p>
                </div>
              </div>

              <h3 class="text-4xl font-maax font-bold uppercase text-black pb-4">Pure Sugarcane Rum</h3>
              <p class="font-maax text-black text-2xl pb-8">
                Our flagship rum begins with sugarcane cultivated by over 150 farmers across Efate. This isn't just any sugarcane—it's part of an 8,000-year legacy that began in Melanesia. Modern sugarcane originated here, carried across the Pacific by ancient Lapita voyagers who shaped the region's culture for millennia.
              </p>
              <p class="font-maax text-black text-xl pb-8">Our rum begins in the fields, where sugarcane is harvested at peak maturity to capture its full depth of flavor. With each stalk pressed immediately after cutting, we preserve the purity of its juice, ensuring that nothing is lost to time or fermentation before we intend it. This fresh extraction is the foundation of our signature style—bold, expressive, and unmistakably tied to Vanuatu’s terroir.
              </p>

              <p class="font-maax text-black text-xl pb-8">
                What sets our sugarcane apart is not just where it grows, but how we transform it. From fermentation through distillation, we embrace a process that amplifies the natural character of the cane, coaxing out layers of grassy freshness, tropical fruit, and deep, complex sweetness. By using slow fermentation and carefully controlled two-step distillation in our traditional and hybrid pot stills, we capture an aromatic intensity that industrial methods simply cannot match.
              </p>

              <p class="font-maax text-black text-xl pb-8">
                Every choice we make—when to harvest, how to ferment, what to refine and what to leave raw—shapes the final expression in the bottle. This is not just about making rum; it’s about honoring an ingredient at its peak, elevating its character, and distilling the very essence of the land into a spirit unlike any other.
              </p>

              <!-- Image Grid: Sugarcane Process -->
              <div class="grid grid-cols-2 gap-4 mb-12">
                <div class="relative h-[300px] rounded-lg overflow-hidden">
                  <img 
                    src="/image/TeoumaValley.jpg" 
                    alt="Sugarcane Fields" 
                    class="w-full h-full object-cover"
                  />
                  <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 to-transparent p-4">
                    <p class="text-white text-sm">Teouma Valley, One of Efate's Prime Sugarcane-Growing Regions.</p>
                  </div>
                </div>
                <div class="relative h-[300px] rounded-lg overflow-hidden">
                  <img 
                    src="/image/HandHarvest.jpg" 
                    alt="Harvesting Process" 
                    class="w-full h-full object-cover"
                  />
                  <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 to-transparent p-4">
                    <p class="text-white text-sm">All our cane is hand-harvested</p>
                  </div>
                </div>
              </div>

              <h3 class="text-2xl font-maax font-bold text-gray-900 mb-4">Molasses Rum: A Bold Expression</h3>
              <p class="text-gray-800 mb-8 leading-relaxed">
                Alongside our pure cane rum, we craft distinctive molasses-based expressions that explore deeper, more caramelized profiles. Through careful fermentation and tropical aging, we unlock layers of dark fruit, spice, and toffee—pushing the boundaries of what rum can be while staying true to our Vanuatu-grown future.
              </p>

              <!-- Image: Barrels -->
              <div class="relative w-full h-[500px] mb-12 rounded-lg overflow-hidden">
                <img 
                  src="/image/rackhouse.jpg" 
                  alt="Aging Barrels" 
                  class="w-full h-full object-cover"
                />
                <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 to-transparent p-4">
                  <p class="text-white text-sm">Our aging warehouse, where tropical climate accelerates maturation</p>
                </div>
              </div>

              <!-- Tropical Aging Section -->
              <h3 class="text-2xl font-maax font-bold text-gray-900 mb-4">The Art of Tropical Aging</h3>
              <div class="flex flex-col md:flex-row gap-8 mb-12">
                <div class="w-full">
                  <p class="text-gray-800 leading-relaxed">
                    In Vanuatu's tropical climate, our rums develop extraordinary depth in a fraction of the time compared to cooler regions. The warmth accelerates the interaction between spirit and oak, while high humidity shapes a unique aging profile.
                  </p>
                </div>
              </div>

              <h3 class="text-2xl font-maax font-bold text-gray-900 mb-4">A Spirit of Resilience</h3>
              <p class="text-gray-800 mb-8 leading-relaxed">
                Like Vanuatu itself, our distillery embodies resilience. Through cyclones, earthquakes, and global challenges, we've adapted and grown stronger. Every bottle of 83 Islands rum carries this spirit of endurance—a testament to the power of tradition, community, and unwavering dedication to craft.
              </p>

              <h3 class="text-2xl font-maax font-bold text-gray-900 mb-4">Beyond Rum</h3>
              <p class="text-gray-800 mb-12 leading-relaxed">
                Our passion extends beyond rum to a carefully curated collection of spirits. From seasonal gins infused with Vanuatu's biodiversity to artisanal liqueurs that capture island flavors, each creation reflects our commitment to quality and innovation.
              </p>

              <!-- Product Grid -->
              <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                <div class="relative rounded-lg overflow-hidden group">
                  <img 
                    src="/image/Cucumber Dry Gin_half.jpg" 
                    alt="Seasonal Gin" 
                    class="w-full h-full object-cover transition-transform group-hover:scale-105"
                  />
                  <div class="flex items-end p-4">
                    <div>
                      <h4 class="text-white font-bold mb-2">Island Gin</h4>
                      <p class="text-white/80 text-sm">Infused with local botanicals</p>
                    </div>
                  </div>
                </div>
                <div class="relative rounded-lg overflow-hidden group">
                  <img 
                    src="/image/Arancio-Verde-Liqueur_half.jpg" 
                    alt="Island Vodka" 
                    class="w-full h-full object-cover transition-transform group-hover:scale-105"
                  />
                  <div class="flex items-end p-4">
                    <div>
                      <h4 class="text-white font-bold mb-2">Pure Vodka</h4>
                      <p class="text-white/80 text-sm">Crystal clear refinement</p>
                    </div>
                  </div>
                </div>
                <div class="relative rounded-lg overflow-hidden group">
                  <img 
                    src="/image/Cacao Nibs_half.jpg" 
                    alt="Artisanal Liqueur" 
                    class="w-full h-full object-cover transition-transform group-hover:scale-105"
                  />
                  <div class="flex items-end p-4">
                    <div>
                      <h4 class="text-white font-bold mb-2">Island Liqueurs</h4>
                      <p class="text-white/80 text-sm">Tropical flavors of Vanuatu</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Right sidebar -->
            <div class="hidden lg:block lg:w-1/3 space-y-8">
              <!-- Featured Product -->
              <div class="bg-white rounded-lg overflow-hidden">
                <div class="relative">
                  <img 
                    src="/image/ARB8_half.jpg" 
                    alt="83 Islands Single Cask 5 Year Old" 
                    class="w-full object-cover"
                  />
                </div>
                <div class="bg-gradient-to-t from-black/5 to-transparent p-6">
                  <h4 class="text-black font-bold text-xl mb-2">83 Islands Single Cask 5 Year Old</h4>
                  <p class="text-black/80">
                    Aged for five years in a single hand-selected cask, this is our most mature expression to date—bold, complex, and deeply shaped by Vanuatu's relentless tropical climate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- SVG Pattern Overlay -->
   <!--      <div 
          class="bottom-8 left-0 right-0 z-10 w-full h-24 bg-repeat-x"
          style="background-image: url('/image/83-Teouma-web.svg');">
        </div> -->
      </main>
      <Footer class="relative" />
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import MainNav from '../components/MainNav.vue'
  import Footer from '../components/Footer.vue';

  const showHeader = ref(true);
    let lastScrollPosition = 0;

    const handleScroll = () => {
        const currentScrollPosition = window.scrollY;
        
        // Show header when scrolling up or at top
        showHeader.value = 
            currentScrollPosition < lastScrollPosition || 
            currentScrollPosition < 50;
        
        lastScrollPosition = currentScrollPosition;
    };

    // Add and remove scroll listener
    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });
</script>


<style>
/* Optional: Add smooth transition for header */
header {
    transition: transform 0.3s ease;
}
</style>
