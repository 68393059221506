// ESM wrapper for crypto-js
import CryptoJSCore from 'crypto-js/core';
import SHA256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';

// Create a simplified API that matches what we need
const CryptoJS = {
  SHA256: function(message) {
    return SHA256(message);
  },
  enc: {
    Hex: Hex
  }
};

export default CryptoJS; 