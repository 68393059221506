<script setup>
import { useHead } from '@vueuse/head'
import { useFacebookPixel } from '../composables/useFacebookPixel';
import { onMounted } from 'vue';

// Initialize Facebook Pixel with enhanced cookie-less support
const { initFacebookPixel, updateConsent, trackBeginBookingProcess, trackViewContent } = useFacebookPixel();

onMounted(() => {
  // Initialize Facebook Pixel when component mounts
  // We assume implicit consent based on the text at the bottom of the page
  initFacebookPixel();
  updateConsent(true);
  
  // Track page view
  trackViewContent({
    content_type: 'homepage',
    content_name: '83 Islands Distillery Homepage'
  });
  
  // Store consent in localStorage to remember user's implicit consent
  if (!localStorage.getItem('cookieConsent')) {
    localStorage.setItem('cookieConsent', JSON.stringify({
      essential: true,
      analytics: true,
      marketing: true
    }));
  }
});

// Track when user clicks "Book Tour" button
const trackBookTour = () => {
  trackBeginBookingProcess();
};

// Track when user clicks "Discover" button
const trackDiscover = () => {
  trackViewContent({
    content_type: 'discover_page',
    content_name: 'Discover Page'
  });
};

// Set meta tags for SEO
useHead({
  title: '83 Islands Distillery - Authentic Vanuatu Rum',
  meta: [
    {
      name: 'description',
      content: 'Discover authentic Vanuatu rum at 83 Islands Distillery. Experience untouched, extraordinary spirits from the South Pacific. Book distillery tours and tastings.'
    },
    // Additional meta tags for better SEO
    {
      name: 'keywords',
      content: 'Vanuatu rum, 83 Islands Distillery, South Pacific distillery, rum tours, authentic rum'
    },
    {
      property: 'og:title',
      content: '83 Islands Distillery - Authentic Vanuatu Rum'
    },
    {
      property: 'og:description',
      content: 'Discover authentic Vanuatu rum at 83 Islands Distillery. Experience untouched, extraordinary spirits from the South Pacific.'
    }
  ]
})
</script>

<template>
  <!-- White Border Overlay -->
  <div class="fixed inset-0 border-white border-[20px] z-50 pointer-events-none"></div>

  <div class="fixed inset-0 overflow-hidden">
    <!-- Video Background -->
    <div class="absolute inset-0 bg-black">
      <video 
        autoplay 
        loop 
        muted 
        playsinline 
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full object-cover"
      >
        <source src="/video/83web_small.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    <!-- Logo and Tagline -->
    <div class="absolute z-20 w-full p-4 lg:p-12 flex flex-col items-center lg:items-start">
      <div class="flex flex-col items-center w-1/2 lg:w-1/6">
        <img 
          src="/image/83IslandsDistillery.svg" 
          alt="83 Islands Distillery" 
          class="drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] mb-4 pl-4 lg:mb-4"
        >
        <h2 class="text-white pb-6 font-maax text-xl pl-4 uppercase font-bold text-center leading-snug drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)]">
          Vanuatu, South Pacific
        </h2>
      </div>
      <h1 class="text-white font-maax px-4 text-2xl uppercase lg:text-5xl lg:pl-8 sm:px-4 font-bold text-center lg:text-left max-w-3xl leading-tight drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)]">
        Discover rum that reconnects you with the world as it was meant to be—untouched, authentic, and extraordinary.
      </h1>

      <!-- Buttons -->
      <div class="flex flex-col sm:flex-row gap-4 lg:pl-8 mt-8 lg:mt-12">
        <router-link 
          to="/discover" 
          class="font-maax px-8 py-3 uppercase font-bold transition-all rounded-lg drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] border-2 text-white hover:bg-white hover:text-black hover:border-white text-center"
          @click="trackDiscover"
        >
          Discover
        </router-link>
        <router-link 
          to="/tours" 
          class="font-maax px-8 py-3 uppercase font-bold transition-all rounded-lg drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] border-2 text-white hover:bg-white hover:text-black hover:border-white text-center"
          @click="trackBookTour"
        >
          Book Tour
        </router-link>

        <!-- <router-link to="/Home" class="font-maax px-8 py-3 uppercase font-bold transition-all rounded-full drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] border-2 hover:border-white hover:text-white" style="border-image: linear-gradient(45deg, #F6D993, #A87539 80%) 1; background: -webkit-linear-gradient(45deg, #F6D993, #A87539 80%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; transition: border-image 0.3s ease, -webkit-text-fill-color 0.3s ease;">
      Discover
    </router-link> -->
       
      </div>
      <p class="font-maax px-4 lg:pl-8 sm:px-4 text-white text-sm mt-4 max-w-md text-center sm:text-left opacity-80">
        By clicking any button, you confirm that you are of legal drinking age in your country and agree to our cookie policy.
        </p>
    </div>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset any default margins and padding */
body {
  @apply m-0 p-0 overflow-hidden;
}

#app {
  @apply w-full h-full;
}
</style>