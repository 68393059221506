import { ref } from 'vue';
import { useRoute } from 'vue-router';
import CryptoJS from '../utils/crypto';

export function useFacebookPixel() {
  const isInitialized = ref(false);
  const route = useRoute();
  
  // Your Facebook Pixel ID and Access Token
  const PIXEL_ID = import.meta.env.VITE_FB_PIXEL_ID || '';
  const ACCESS_TOKEN = import.meta.env.VITE_FB_ACCESS_TOKEN || '';
  const API_VERSION = 'v18.0';
  
  // Check if we're in development mode
  const isDevelopment = import.meta.env.MODE === 'development';
  
  // Initialize the Facebook Pixel
  const initFacebookPixel = () => {
    if (isInitialized.value) return true;
    
    // Enhanced environment variable check
    if (!PIXEL_ID) {
      console.warn('Facebook Pixel ID not found. Please set VITE_FB_PIXEL_ID in your environment variables.');
      return false;
    }
    
    try {
      // Check if fbq is already initialized
      if (window.fbq) {
        console.log('Facebook Pixel already initialized, skipping initialization');
        isInitialized.value = true;
        return true;
      }
      
      // Load Facebook Pixel script
      !function(f,b,e,v,n,t,s) {
        if(f.fbq) return;
        n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      
      // Initialize with the Pixel ID
      window.fbq('init', PIXEL_ID);
      window.fbq('track', 'PageView');
      
      isInitialized.value = true;
      console.log('Facebook Pixel initialized successfully');
      return true;
    } catch (error) {
      console.error('Error initializing Facebook Pixel:', error);
      return false;
    }
  };
  
  // Update consent when user provides it
  const updateConsent = (hasConsent = true) => {
    if (!isInitialized.value) {
      initFacebookPixel();
    }
    
    try {
      if (hasConsent) {
        window.fbq('consent', 'grant', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });
        console.log('Facebook Pixel consent granted');
      } else {
        window.fbq('consent', 'revoke', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied'
        });
        console.log('Facebook Pixel consent revoked');
      }
      return true;
    } catch (error) {
      console.error('Error updating Facebook Pixel consent:', error);
      return false;
    }
  };
  
  // Safe wrapper for fbq function
  const safeFbq = (eventType, eventName, params = {}, options = {}) => {
    if (!isInitialized.value) {
      const initialized = initFacebookPixel();
      if (!initialized) {
        console.warn(`Skipping Facebook Pixel event (${eventName}) - initialization failed`);
        return false;
      }
    }
    
    if (typeof window.fbq === 'undefined') {
      console.warn(`Skipping Facebook Pixel event (${eventName}) - fbq is not defined`);
      return false;
    }
    
    try {
      // For custom events, use trackCustom
      if (eventType === 'track' && !['ViewContent', 'InitiateCheckout', 'Purchase'].includes(eventName)) {
        window.fbq('trackCustom', eventName, params, options);
      } else {
        window.fbq(eventType, eventName, params, options);
      }
      
      console.log(`Successfully sent ${eventName} event to Facebook Pixel`, {
        eventType,
        eventName,
        paramFields: Object.keys(params),
        options
      });
      
      return true;
    } catch (error) {
      console.error(`Error sending ${eventName} event to Facebook Pixel:`, error);
      return false;
    }
  };
  
  // Generate a unique event ID for deduplication
  const generateEventId = () => {
    return `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;
  };
  
  // Hash user data for privacy
  const hashData = (data) => {
    if (!data) return null;
    
    try {
      // Try using CryptoJS
      return CryptoJS.SHA256(data.trim().toLowerCase()).toString(CryptoJS.enc.Hex);
    } catch (error) {
      console.warn('CryptoJS failed, using fallback hashing method:', error);
      
      // Simple fallback hashing function
      // Note: This is not as secure as SHA-256 but will work as a fallback
      const str = data.trim().toLowerCase();
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
      }
      
      // Convert to hex string and ensure it's 64 characters long (like SHA-256)
      const hexHash = Math.abs(hash).toString(16).padStart(64, '0');
      return hexHash;
    }
  };
  
  // Get Facebook browser cookies for better matching
  const getFacebookCookies = () => {
    try {
      const cookies = document.cookie.split(';').reduce((acc, cookie) => {
        const [name, value] = cookie.trim().split('=');
        acc[name] = value;
        return acc;
      }, {});
      
      return {
        fbc: cookies._fbc || undefined,
        fbp: cookies._fbp || undefined
      };
    } catch (error) {
      console.warn('Error getting Facebook cookies:', error);
      return {};
    }
  };
  
  // Get browser metadata for improved server-side matching
  const getBrowserMetadata = () => {
    try {
      return {
        client_user_agent: navigator.userAgent,
        client_ip_address: undefined, // This will be collected server-side
        event_source_url: window.location.href,
        browser_language: navigator.language,
        http_referrer: document.referrer,
        document_location_url: document.location.href,
        document_hostname: document.location.hostname,
        document_path: document.location.pathname
      };
    } catch (error) {
      console.warn('Error getting browser metadata:', error);
      return {};
    }
  };
  
  // Direct API call to Facebook as a fallback
  const sendDirectToFacebook = async (eventName, userData = {}, customData = {}, eventId = null) => {
    if (!PIXEL_ID || !ACCESS_TOKEN) {
      console.warn('Missing Facebook credentials for direct API call, falling back to browser pixel');
      return safeFbq('track', eventName, customData, { eventID: eventId });
    }
    
    try {
      // Generate a unique event ID if not provided
      const finalEventId = eventId || generateEventId();
      
      // Get browser metadata
      const browserMetadata = getBrowserMetadata();
      
      // Get Facebook click ID and browser ID from cookies
      const fbCookies = getFacebookCookies();
      
      // Hash user data for privacy - only include allowed fields
      const hashedUserData = {
        em: userData.email ? hashData(userData.email.toLowerCase().trim()) : undefined,
        ph: userData.phone ? hashData(userData.phone.trim()) : undefined,
        fn: userData.firstName ? hashData(userData.firstName.toLowerCase().trim()) : undefined,
        ln: userData.lastName ? hashData(userData.lastName.toLowerCase().trim()) : undefined,
        external_id: userData.userId ? hashData(userData.userId.trim()) : undefined,
        fbc: fbCookies.fbc || undefined,
        fbp: fbCookies.fbp || undefined,
        client_ip_address: '0.0.0.0',
        client_user_agent: navigator.userAgent,
        country: hashData('VU')
      };
      
      // Remove undefined values
      Object.keys(hashedUserData).forEach(key => {
        if (hashedUserData[key] === undefined) {
          delete hashedUserData[key];
        }
      });
      
      // Prepare the event data
      const eventData = {
        data: [
          {
            event_name: eventName,
            event_time: Math.floor(Date.now() / 1000),
            event_id: finalEventId,
            event_source_url: browserMetadata.document_location_url || window.location.href,
            action_source: 'website',
            user_data: hashedUserData,
            custom_data: {
              ...customData,
              currency: customData.currency || 'VUV',
              value: customData.value || 0
            }
          }
        ]
      };
      
      // Debug log the request (without sensitive data)
      console.log('Facebook API Request:', {
        url: `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events`,
        eventName,
        eventId: finalEventId,
        hashedUserDataFields: Object.keys(hashedUserData),
        customDataFields: Object.keys(customData)
      });
      
      // Send to Facebook Conversions API
      const response = await fetch(
        `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${ACCESS_TOKEN}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(eventData),
        }
      );
      
      const responseData = await response.json();
      
      if (!response.ok) {
        console.error('Facebook API error:', {
          status: response.status,
          statusText: response.statusText,
          error: responseData.error
        });
        
        // Fall back to browser pixel
        console.log('Falling back to browser pixel due to API error');
        return safeFbq('track', eventName, customData, { eventID: finalEventId });
      }
      
      console.log('Facebook API success:', {
        eventName,
        eventId: finalEventId,
        success: true
      });
      
      return { success: true, result: responseData };
    } catch (error) {
      console.error('Error in direct Facebook API call:', {
        error: error.message,
        eventName,
        stack: error.stack
      });
      
      // Fall back to browser pixel
      console.log('Falling back to browser pixel due to error');
      return safeFbq('track', eventName, customData, { eventID: eventId });
    }
  };
  
  // Send event to Facebook Conversions API via our serverless function
  const sendServerEvent = async (eventName, userData = {}, customData = {}) => {
    // Skip server events in development mode to avoid errors
    if (isDevelopment) {
      console.log(`[DEV MODE] Skipping server-side event: ${eventName}`);
      return { success: true, devMode: true };
    }
    
    try {
      // Generate a unique event ID for deduplication
      const eventId = generateEventId();
      
      // Get Facebook cookies
      const fbCookies = getFacebookCookies();
      
      // Get browser metadata for improved matching
      const browserMetadata = getBrowserMetadata();
      
      // Prepare the event data with enhanced validation
      const eventData = {
        eventName,
        eventId,
        userData: {
          ...userData,
          ...fbCookies
        },
        customData: {
          ...customData,
          currency: customData.currency || 'VUV',
          value: customData.value || 0
        },
        browserMetadata: {
          ...browserMetadata,
          event_source_url: window.location.href
        }
      };
      
      console.log(`Sending ${eventName} event to server endpoint`, { 
        eventId, 
        hasUserData: Object.keys(userData).length > 0,
        hasCustomData: Object.keys(customData).length > 0
      });
      
      // Determine the API URL based on environment
      const apiUrl = '/api/facebook-events';
      
      // Send to our serverless function with timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 second timeout
      
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(eventData),
          signal: controller.signal
        });
        
        clearTimeout(timeoutId);
        
        if (!response.ok) {
          let errorDetails;
          try {
            errorDetails = await response.json();
          } catch (e) {
            errorDetails = { message: `Server returned ${response.status} ${response.statusText}` };
          }
          
          console.warn(`Server-side event tracking failed (${response.status}):`, errorDetails);
          
          // Immediately fall back to direct API call
          console.warn('Falling back to direct API call');
          return await sendDirectToFacebook(eventName, userData, customData, eventId);
        }
        
        const result = await response.json();
        console.log('Event sent to Facebook successfully via server:', result);
        return result;
      } catch (fetchError) {
        clearTimeout(timeoutId);
        throw fetchError;
      }
    } catch (error) {
      console.error('Error sending event to Facebook via server:', error);
      
      // Always fall back to direct API call on server errors
      console.warn('Server error, falling back to direct API call');
      return await sendDirectToFacebook(eventName, userData, customData);
    }
  };
  
  // Track ViewContent event
  const trackViewContent = async (contentData = {}) => {
    try {
      const eventId = generateEventId();
      
      // Prepare content data with required fields
      const enhancedContentData = {
        content_type: contentData.content_type || 'product',
        content_name: contentData.content_name || document.title,
        content_ids: contentData.content_ids || [],
        currency: 'VUV',
        value: contentData.value || 0,
        ...contentData
      };
      
      // Try direct API call first
      const result = await sendDirectToFacebook('ViewContent', {}, enhancedContentData, eventId);
      
      // If direct API call fails, browser pixel fallback is handled in sendDirectToFacebook
      return result;
    } catch (error) {
      console.error('Error tracking ViewContent event:', error);
      return false;
    }
  };
  
  // Track InitiateCheckout event
  const trackInitiateCheckout = async (userData = {}, checkoutData = {}) => {
    try {
      const eventId = generateEventId();
      
      // Prepare checkout data
      const enhancedCheckoutData = {
        content_type: checkoutData.content_type || 'tour',
        content_name: checkoutData.content_name || 'Tour Booking',
        content_ids: checkoutData.content_ids || [],
        currency: 'VUV',
        value: checkoutData.value || 0,
        num_items: checkoutData.num_items || 1,
        ...checkoutData
      };
      
      // Try direct API call first with user data
      const result = await sendDirectToFacebook('InitiateCheckout', userData, enhancedCheckoutData, eventId);
      
      // If direct API call fails, browser pixel fallback is handled in sendDirectToFacebook
      return result;
    } catch (error) {
      console.error('Error tracking InitiateCheckout event:', error);
      return false;
    }
  };
  
  // Track AddToCart event
  const trackAddToCart = (userData = {}, cartData = {}) => {
    try {
      // Create a unique event ID for deduplication
      const eventId = generateEventId();
      
      // Send to browser pixel
      const pixelSent = safeFbq('track', 'AddToCart', cartData, { eventID: eventId });
      
      // Always send to server API for cookie-less tracking
      sendServerEvent('AddToCart', userData, {
        ...cartData,
        currency: cartData.currency || 'VUV',
        value: cartData.value || 0,
        content_ids: cartData.content_ids || [],
        content_name: cartData.content_name || '',
        content_type: cartData.content_type || 'product',
        contents: cartData.contents || []
      });
      
      return pixelSent;
    } catch (error) {
      console.error('Error tracking AddToCart event:', error);
      return false;
    }
  };
  
  // Track Purchase event
  const trackPurchase = (userData = {}, purchaseData = {}) => {
    try {
      // Create a unique event ID for deduplication
      const eventId = generateEventId();
      
      // Send to browser pixel
      const pixelSent = safeFbq('track', 'Purchase', purchaseData, { eventID: eventId });
      
      // Always send to server API for cookie-less tracking
      sendServerEvent('Purchase', userData, {
        ...purchaseData,
        currency: purchaseData.currency || 'VUV',
        value: purchaseData.value || 0,
        content_ids: purchaseData.content_ids || [],
        contents: purchaseData.contents || [],
        num_items: purchaseData.num_items || 0,
        transaction_id: purchaseData.transaction_id || generateEventId()
      });
      
      return pixelSent;
    } catch (error) {
      console.error('Error tracking Purchase event:', error);
      return false;
    }
  };
  
  // Track ViewTours event (custom event)
  const trackViewTours = () => {
    try {
      // Create a unique event ID for deduplication
      const eventId = generateEventId();
      
      // Send to browser pixel
      const pixelSent = safeFbq('trackCustom', 'ViewTours', {}, { eventID: eventId });
      
      // Always send to server API for cookie-less tracking
      sendServerEvent('ViewTours', {}, {});
      
      return pixelSent;
    } catch (error) {
      console.error('Error tracking ViewTours event:', error);
      return false;
    }
  };
  
  // Track BeginBookingProcess event (custom event)
  const trackBeginBookingProcess = () => {
    try {
      // Create a unique event ID for deduplication
      const eventId = generateEventId();
      
      // Send to browser pixel
      const pixelSent = safeFbq('trackCustom', 'BeginBookingProcess', {}, { eventID: eventId });
      
      // Always send to server API for cookie-less tracking
      sendServerEvent('BeginBookingProcess', {}, {});
      
      return pixelSent;
    } catch (error) {
      console.error('Error tracking BeginBookingProcess event:', error);
      return false;
    }
  };
  
  // Track SelectTourDateTime event
  const trackSelectTourDateTime = async (tourData = {}) => {
    try {
      const eventId = generateEventId();
      
      // Prepare tour data
      const enhancedTourData = {
        content_type: 'tour',
        content_name: tourData.tourType || 'Tour Booking',
        content_ids: [tourData.tourId],
        currency: 'VUV',
        value: tourData.tourType === 'Tour and Tasting' ? 4000 : 2500,
        ...tourData
      };
      
      // Try direct API call first
      const result = await sendDirectToFacebook('SelectTourDateTime', {}, enhancedTourData, eventId);
      
      // If direct API call fails, browser pixel fallback is handled in sendDirectToFacebook
      return result;
    } catch (error) {
      console.error('Error tracking SelectTourDateTime event:', error);
      return false;
    }
  };
  
  // Track InitiateBookingForm event
  const trackInitiateBookingForm = async (bookingData = {}) => {
    try {
      const eventId = generateEventId();
      
      // Prepare booking data
      const enhancedBookingData = {
        content_type: 'tour',
        content_name: bookingData.tourType || 'Tour Booking',
        content_ids: [bookingData.tourId],
        currency: 'VUV',
        value: bookingData.value || (bookingData.tourType === 'Tour and Tasting' ? 4000 : 2500),
        ...bookingData
      };
      
      // Try direct API call first
      const result = await sendDirectToFacebook('InitiateBookingForm', {}, enhancedBookingData, eventId);
      
      // If direct API call fails, browser pixel fallback is handled in sendDirectToFacebook
      return result;
    } catch (error) {
      console.error('Error tracking InitiateBookingForm event:', error);
      return false;
    }
  };
  
  // Track CompleteBooking event
  const trackCompleteBooking = async (userData = {}, bookingData = {}) => {
    try {
      const eventId = generateEventId();
      
      // Prepare booking data
      const enhancedBookingData = {
        content_type: 'tour',
        content_name: bookingData.tourType || 'Tour Booking',
        content_ids: [bookingData.tourId],
        currency: 'VUV',
        value: bookingData.totalPrice || (bookingData.tourType === 'Tour and Tasting' ? 4000 : 2500),
        num_items: bookingData.guestCount || 1,
        transaction_id: bookingData.reference,
        ...bookingData
      };
      
      // Try direct API call first with user data
      const result = await sendDirectToFacebook('Purchase', userData, enhancedBookingData, eventId);
      
      // If direct API call fails, browser pixel fallback is handled in sendDirectToFacebook
      return result;
    } catch (error) {
      console.error('Error tracking CompleteBooking event:', error);
      return false;
    }
  };
  
  // Return the composable functions
  return {
    initFacebookPixel,
    updateConsent,
    trackViewContent,
    trackInitiateCheckout,
    trackAddToCart,
    trackPurchase,
    trackViewTours,
    trackBeginBookingProcess,
    trackSelectTourDateTime,
    trackInitiateBookingForm,
    trackCompleteBooking
  };
} 