import { ref, onMounted, onUnmounted } from 'vue'

export function useScroll(threshold = 50) {
  const scrolled = ref(false)
  const showHeader = ref(true)

  const handleScroll = () => {
    const currentScrollPosition = window.scrollY
    scrolled.value = currentScrollPosition > threshold
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  return {
    scrolled,
    showHeader
  }
} 