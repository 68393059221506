<template>
  <div class="bg-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md mx-auto text-center">
      <!-- Loading State -->
      <div v-if="isLoading" class="text-center">
        <p class="text-gray-600">Loading booking details...</p>
      </div>

      <!-- Error State -->
      <div v-else-if="error" class="bg-red-50 rounded-lg p-6 mb-8">
        <p class="text-red-800">{{ error }}</p>
        <router-link 
          to="/tours" 
          class="inline-block mt-4 bg-gold text-black px-6 py-3 rounded-md font-medium hover:bg-opacity-80"
        >
          Return to Tours
        </router-link>
      </div>

      <!-- Success State -->
      <template v-else>
        <h2 class="text-2xl font-extrabold text-gray-900 mb-8">
          Thank you {{ firstName }} {{ lastName }} for booking with 83 Islands Distillery!
        </h2>
        <h2 class="text-3xl font-extrabold mb-8" :class="isCancelled ? 'text-red-600' : 'text-gray-900'">
          {{ isCancelled ? 'This booking has been cancelled' : 'Booking Confirmed!' }}
        </h2>
        <p v-if="isCancelled" class="text-sm text-gray-600 mb-8">
          If this is a mistake, please <router-link to="/tours" class="text-gold hover:underline">rebook here</router-link>
        </p>

        <!-- Email Confirmation -->
        <div class="bg-green-50 rounded-lg p-6 mb-8">
          <p class="text-green-800">
            A confirmation email has been sent to <span class="font-bold">{{ email }}</span>
          </p>
        </div>

        <!-- Booking Reference -->
        <div class="bg-gray-50 rounded-lg p-6 mb-8">
          <p class="text-lg font-medium text-gray-900">Booking Reference</p>
          <p class="text-2xl font-bold text-gold mb-4">{{ bookingReference }}</p>
          <p class="text-sm text-gray-500 mb-4">Please save this reference number or bookmark this page</p>
          <button 
            @click="shareBooking" 
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold"
          >
            <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
            </svg>
            Share / Save Booking
          </button>
        </div>

        <!-- Tour Details -->
        <div class="bg-white shadow rounded-lg p-6 mb-8">          
          <!-- Tour Type -->
          <div class="text-center mb-8">
            <h3 class="text-3xl font-bold text-gold mb-2">
              {{ tourType === 'Tour and Tasting' ? 'Tour and Tasting' : 'Cellar Door Tasting' }}
            </h3>
          </div>

          <!-- Date and Time Row -->
          <div class="flex justify-between items-center mb-8">
             <!-- Date -->
             <div class="text-center border border-gray-300 rounded-lg p-4">
              <p class="text-4xl font-bold text-gray-900">{{ dayOfMonth }}</p>
              <p class="text-xl text-gray-600">{{ monthAndYear }}</p>
            </div>
          

            <!-- Time -->
            <div class="text-center border border-gray-300 rounded-lg p-4">
              <p class="text-4xl font-bold text-gray-900">{{ time }}</p>
              <p class="text-sm text-gray-500">Tour Start Time</p>
            </div>
          </div>
           
          
          <!-- Guest Count -->
          <div class="text-center mb-8">
            <p class="text-5xl font-bold text-gray-900 mb-2">{{ guestCount }}</p>
            <p class="text-gray-600">{{ guestCount > 1 ? 'Guests' : 'Guest' }}</p>
          </div>
          
          <!-- Price Details -->
          <div class="border-t pt-4">
            <p class="text-lg font-medium text-gray-900 mb-2">
              Total Amount: {{ formatPrice(totalPrice) }} VT
            </p>
            <p class="text-sm text-gray-600 mb-2">
              You will only be charged for guests who are present.
              If some of your guests cannot make it, you will only pay for those who attend.
            </p>
            <p v-if="tourType === 'Tour and Tasting'" class="text-sm text-gold">
              <!-- Duty Free Rebate: {{ formatPrice(guestCount * 1000) }} VT -->
            </p>
            
            <!-- Cancel Booking Button -->
            <button
              v-if="!isCancelled"
              @click="confirmCancellation"
              class="mt-4 w-full inline-flex justify-center items-center px-4 py-2 border border-red-300 text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel Booking
            </button>
            <p v-else class="mt-4 text-sm text-red-600 font-medium">
              This booking has been cancelled
            </p>
          </div>
        </div>

        <!-- Return Home Button -->
        <router-link 
          to="/tours" 
          class="inline-block bg-gold text-black px-6 py-3 rounded-md font-medium hover:bg-opacity-80"
        >
          Return to Tours
        </router-link>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { format, parseISO } from 'date-fns'
import axios from 'axios'
import { useFacebookPixel } from '../composables/useFacebookPixel'

const route = useRoute()
const bookingReference = ref('')
const firstName = ref('')
const lastName = ref('')
const email = ref('')
const formattedDate = ref('')
const time = ref('')
const guestCount = ref(0)
const totalPrice = ref(0)
const isLoading = ref(true)
const error = ref(null)
const tourType = ref('')
const isCancelled = ref(false)
const tourId = ref(null)
const tourDate = ref('')

// Import Facebook Pixel
const { initFacebookPixel, updateConsent, trackCompleteBooking } = useFacebookPixel()

// Create a more robust API configuration
const api = axios.create({
  baseURL: import.meta.env.DEV 
    ? '/api' // Use relative path for development with Vite's proxy
    : import.meta.env.VITE_API_URL || '/api',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  // Add timeout for better reliability
  timeout: 10000
})

// Format price with thousand separators
const formatPrice = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const shareBooking = async () => {
  const bookingUrl = window.location.href
  const shareData = {
    title: '83 Islands Tour Booking',
    text: `Booking Reference: ${bookingReference.value}`,
    url: bookingUrl
  }

  try {
    if (navigator.share) {
      await navigator.share(shareData)
    } else if (navigator.clipboard) {
      await navigator.clipboard.writeText(bookingUrl)
      alert('Booking link copied to clipboard!')
    } else {
      alert('Please manually copy the URL from your browser address bar')
    }
  } catch (err) {
    console.error('Error sharing:', err)
  }
}

const cancelBooking = async (reference) => {
  try {
    const response = await api.delete('/bookings', {
      params: { reference }
    })
    
    if (response.data.success) {
      // Track successful cancellation
      if (window.gtag) {
        gtag('event', 'cancel_booking', {
          event_category: 'Bookings',
          event_label: reference,
          tour_type: tourType.value,
          value: totalPrice.value
        })
      }
      
      isCancelled.value = true
    } else {
      throw new Error(response.data.message || 'Failed to cancel booking')
    }
  } catch (error) {
    // Track cancellation error
    if (window.gtag) {
      gtag('event', 'cancellation_error', {
        event_category: 'Errors',
        event_label: error.message,
        booking_reference: reference
      })
    }
    
    console.error('Error cancelling booking:', error)
    throw error
  }
}

const confirmCancellation = async () => {
  const isConfirmed = window.confirm('Are you sure you want to cancel this booking? This action cannot be undone.')
  
  if (isConfirmed) {
    try {
      await cancelBooking(bookingReference.value)
      isCancelled.value = true
    } catch (error) {
      console.error('Booking cancellation error:', error)
      alert('Failed to cancel booking. Please try again.')
    }
  }
}

onMounted(async () => {
  // Initialize Facebook Pixel with implicit consent
  initFacebookPixel();
  updateConsent(true);
  
  const reference = route.query.ref
  if (!reference) {
    error.value = 'No booking reference provided'
    isLoading.value = false
    return
  }

  try {
    const response = await api.get('/bookings', {
      params: { reference }
    })
    
    const bookingData = response.data
    
    // Set booking data
    bookingReference.value = bookingData.reference
    firstName.value = bookingData.firstName
    lastName.value = bookingData.lastName
    email.value = bookingData.email
    tourId.value = bookingData.tourId
    tourType.value = bookingData.tourType
    tourDate.value = bookingData.date
    
    // Handle time and date formatting with better error handling
    try {
      if (bookingData.start) {
        const bookingDate = new Date(bookingData.start * 1000)
        time.value = format(bookingDate, 'HH:mm')
        formattedDate.value = format(bookingDate, 'MMMM d, yyyy')
      } else {
        time.value = bookingData.time
        // Ensure we have a valid date before parsing
        if (bookingData.date && typeof bookingData.date === 'string') {
          formattedDate.value = format(parseISO(bookingData.date), 'MMMM d, yyyy')
        } else {
          throw new Error('Invalid date format received from server')
        }
      }
    } catch (dateError) {
      console.error('Error parsing date:', dateError)
      formattedDate.value = 'Date format error'
      time.value = bookingData.time || 'Time not available'
    }
    
    guestCount.value = bookingData.guestCount
    totalPrice.value = bookingData.totalPrice || bookingData.cost
    isCancelled.value = bookingData.status === 'cancelled'
    
    // Track completed booking with Facebook Pixel if not cancelled
    if (!isCancelled.value) {
      trackCompleteBooking(
        {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value
        },
        {
          tourId: tourId.value,
          tourType: tourType.value,
          date: tourDate.value,
          time: time.value,
          guestCount: guestCount.value,
          totalPrice: totalPrice.value,
          reference: bookingReference.value
        }
      );
    }
    
  } catch (error) {
    console.error('Error fetching booking details:', error)
    error.value = 'Error fetching booking details. Please try refreshing the page.'
  } finally {
    isLoading.value = false
  }
})

// Update computed properties with better error handling
const dayOfMonth = computed(() => {
  if (!formattedDate.value) return ''
  try {
    return format(new Date(formattedDate.value), 'd')
  } catch (error) {
    console.error('Error formatting day of month:', error)
    return ''
  }
})

const monthAndYear = computed(() => {
  if (!formattedDate.value) return ''
  try {
    return format(new Date(formattedDate.value), 'MMMM yyyy')
  } catch (error) {
    console.error('Error formatting month and year:', error)
    return ''
  }
})
</script> 