<template>
  <div class="bg-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md mx-auto">
      <h2 class="text-3xl font-extrabold text-gray-900 text-center mb-8">
        Almost done! Complete this form to confirm your booking.
      </h2>

      <!-- Guest Counter Section -->
      <div class="mb-6 text-center">
        <p class="text-xl font-semibold text-gray-700">{{ formattedTourDate }} at {{ tourTime }}</p>
        <p class="text-lg font-semibold text-gray-700">{{ tourType }}</p>
        <p class="text-sm text-gray-500 mb-2">Please ensure you arrive on time.</p>

        <div class="mt-4">
          <p class="text-xl font-medium text-gray-700">Number of Guests</p>
          <p class="text-8xl font-bold text-gray-900 my-4">{{ guestCount }}</p>
          
          <!-- Guest Counter Buttons -->
          <div class="flex justify-center space-x-4 mb-2">
            <button
              @click="decrementGuests"
              :disabled="guestCount <= 1"
              :class="[
                'py-4 px-8 rounded-md text-3xl font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold',
                guestCount <= 1 
                  ? 'bg-gray-200 text-gray-400' 
                  : 'bg-gold text-black hover:bg-opacity-80'
              ]"
            >
              -
            </button>
            <button
              @click="incrementGuests"
              :disabled="guestCount >= availableSpots || guestCount >= MAX_CAPACITY"
              :class="[
                'py-4 px-8 rounded-md text-3xl font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold',
                guestCount >= availableSpots || guestCount >= MAX_CAPACITY
                  ? 'bg-gray-200 text-gray-400'
                  : 'bg-gold text-black hover:bg-opacity-80'
              ]"
            >
              +
            </button>
          </div>

          <!-- Capacity Message -->
          <p v-if="guestCount >= availableSpots" class="text-sm text-red-600 mt-2">
            Maximum capacity reached for this time slot.
          </p>

          <p class="text-sm text-gray-500">Only count adults.</p>
          <p class="text-sm text-gray-500 mb-4">Underage guests are free.</p>
          
          <!-- Large Group Message -->
          <div v-if="showLargeGroupMessage" class="mt-4 p-4 bg-gray-50 rounded-lg">
            <p class="text-sm text-gray-700">For groups of more than 16, please email 
              <a href="mailto:events@83islands.com" class="text-gold hover:underline">events@83islands.com</a> 
              or <a href="/contact#event-booking" class="text-gold hover:underline">fill out our event form</a>.
            </p>
          </div>
        </div>
      </div>

      <form @submit.prevent="submitBooking" class="space-y-6">
        <div>
          <label for="firstName" class="block text-sm font-medium text-gray-700">First Name (required)</label>
          <input type="text" id="firstName" v-model="firstName" required
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gold focus:border-gold bg-white text-gray-900">
        </div>
        <div>
          <label for="lastName" class="block text-sm font-medium text-gray-700">Last Name (required)</label>
          <input type="text" id="lastName" v-model="lastName" required
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gold focus:border-gold bg-white text-gray-900">
        </div>
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">Email (required)</label>
          <input type="email" id="email" v-model="email" required
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gold focus:border-gold bg-white text-gray-900">
        </div>

        <!-- Language Selection -->
        <div class="mb-6">
          <label class="block text-sm font-medium text-gray-700 mb-2 text-center">Preferred Language</label>
          <div class="flex justify-center space-x-4">
            <button
              type="button"
              @click="preferredLanguage = 'English'"
              :disabled="existingLanguage && existingLanguage !== 'English'"
              :class="[
                'py-2 px-4 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold',
                preferredLanguage === 'English'
                  ? 'bg-gold text-black'
                  : existingLanguage && existingLanguage !== 'English'
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              ]"
            >
              English
            </button>
            <button
              type="button"
              @click="preferredLanguage = 'French'"
              :disabled="existingLanguage && existingLanguage !== 'French' || isFrenchBlocked"
              :class="[
                'py-2 px-4 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold',
                preferredLanguage === 'French'
                  ? 'bg-gold text-black'
                  : existingLanguage && existingLanguage !== 'French' || isFrenchBlocked
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              ]"
            >
              French
            </button>
          </div>
          
          <!-- Language Lock Messages -->
          <div class="mt-2 text-center">
            <p v-if="existingLanguage" class="text-sm text-gray-600">
              This tour is already scheduled in {{ existingLanguage }}
            </p>
            <p v-else-if="isFrenchBlocked" class="text-sm text-gray-600">
              Tours between 11 AM and 2 PM are only available in English
            </p>
            <template v-else-if="preferredLanguage === 'French'">
              <p class="text-center text-sm text-gray-600 pt-2">Nous ferons de notre mieux pour accommoder votre langue préférée.</p>
              <p class="text-center text-sm text-gray-600 pt-2">We will do our best to accommodate your preferred language.</p>
            </template>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="mt-6">
          <button type="submit"
                  :disabled="isSubmitting"
                  class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-black bg-gold hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold disabled:opacity-50 disabled:cursor-not-allowed">
            <template v-if="isSubmitting">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </template>
            <template v-else>
              Confirm Booking for {{ guestCount }} guest{{ guestCount > 1 ? 's' : '' }}
            </template>
          </button>
        </div>

        <!-- Pricing Information -->
        <div class="space-y-2 text-center">
          <p class="text-sm text-center text-gray-500 mb-2">
            Pay Later {{ tourType === 'Tour and Tasting' ? '4,000' : '2,500' }} VT per adult
          </p>
          <p class="text-lg font-medium text-gray-900">{{ totalPrice.toLocaleString() }} VT total</p>
          <!-- <p v-if="tourType === 'Tour and Tasting'" class="text-sm font-bold text-gold">
            You will receive {{ (guestCount * 1000).toLocaleString() }} VT credit on any 750ML bottles purchase
          </p> -->
        </div>
      </form>

      <!-- Return to Tours Button -->
      <div class="mt-8 text-center">
        <button
          @click="router.push('/tours')"
          class="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gold"
        >
          <svg class="mr-2 -ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          Return to Tours
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { format, parseISO } from 'date-fns'
import axios from 'axios'
import { useFacebookPixel } from '../composables/useFacebookPixel'

const route = useRoute()
const router = useRouter()
const error = ref(null)
const firstName = ref('')
const lastName = ref('')
const email = ref('')
const preferredLanguage = ref('English')

// Initialize Facebook Pixel
const { initFacebookPixel, updateConsent, trackInitiateBookingForm, trackInitiateCheckout } = useFacebookPixel()

// Get URL parameters
const tourDate = ref('')
const tourTime = ref('')
const tourId = ref(null)
const formattedTourDate = ref('')
const tourType = ref('')

const guestCount = ref(1)
const showLargeGroupMessage = ref(false)
const MAX_CAPACITY = 16
const isSpaceAvailable = ref(true)
const currentBookings = ref(0)
const availableSpots = ref(MAX_CAPACITY)


// Add new ref for existing language
const existingLanguage = ref(null)

// Create a more robust API configuration
const api = axios.create({
  baseURL: import.meta.env.DEV 
    ? '/api' // Use relative path for development with Vite's proxy
    : import.meta.env.VITE_API_URL || '/api',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  // Add timeout and retry logic for better reliability
  timeout: 10000
})

// Add debugging log only in development
if (import.meta.env.DEV) {
  console.log('API Configuration:', {
    baseURL: api.defaults.baseURL,
    mode: import.meta.env.MODE,
    isDev: import.meta.env.DEV,
    apiUrl: import.meta.env.VITE_API_URL,
    timestamp: new Date().toISOString()
  })
}

const testDbConnection = async () => {
  try {
    console.log('Testing MongoDB connection...')
    const response = await api.get('/db-test')
    console.log('MongoDB Connection Test Result:', response.data)
    return response.data
  } catch (error) {
    console.error('MongoDB Connection Test Failed:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    })
    throw error
  }
}

const checkAvailability = async () => {
  try {
    console.log('Checking availability for:', {
      date: tourDate.value,
      time: tourTime.value,
      tourType: tourType.value,
      requestedGuests: guestCount.value
    })

    const response = await api.get('/bookings', {
      params: { date: tourDate.value }
    })
    
    // Get bookings for the specific time and tour type
    const dateBookings = response.data[tourDate.value] || {}
    const timeBookings = dateBookings[tourTime.value] || {}
    const tourBookings = timeBookings[tourType.value] || { count: 0, language: null }
    
    // Update reactive refs
    currentBookings.value = tourBookings.count
    availableSpots.value = MAX_CAPACITY - currentBookings.value
    existingLanguage.value = tourBookings.language
    
    // If there's an existing language, use it
    if (existingLanguage.value) {
      preferredLanguage.value = existingLanguage.value
    }
    
    // Check if there's enough space for the requested group
    isSpaceAvailable.value = (currentBookings.value + guestCount.value) <= MAX_CAPACITY
    
    console.log('Availability check result:', {
      currentBookings: currentBookings.value,
      availableSpots: availableSpots.value,
      requestedGuests: guestCount.value,
      isSpaceAvailable: isSpaceAvailable.value,
      existingLanguage: existingLanguage.value
    })

    // Set error message if not enough space
    if (!isSpaceAvailable.value) {
      error.value = availableSpots.value <= 0 
        ? 'This time slot is fully booked.' 
        : `Only ${availableSpots.value} spots available.`
    } else {
      error.value = null
    }

    return isSpaceAvailable.value
  } catch (error) {
    console.error('Availability check error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      timestamp: new Date().toISOString()
    })
    throw error
  }
}

// Call checkAvailability when guest count changes
watch(guestCount, async (newCount) => {
  try {
    await checkAvailability()
    
    // Track InitiateCheckout event when guest count changes
    trackInitiateCheckout({}, {
      content_type: 'tour',
      content_name: tourType.value,
      content_ids: [tourId.value],
      currency: 'VUV',
      value: totalPrice.value,
      num_items: newCount
    });
  } catch (error) {
    console.error('Error checking availability after guest count change:', error)
  }
})

onMounted(() => {
  // Initialize Facebook Pixel with implicit consent
  initFacebookPixel();
  updateConsent(true);
  
  // Test database connection
  //await testDbConnection()
  
  // Get query parameters
  const { date, time, tourId: id, tourType: type } = route.query

  if (!date || !time || !id || !type) {
    error.value = 'Missing booking information'
    return
  }

  // Validate tour type
  if (type !== 'Tour and Tasting' && type !== 'Cellar Door Tasting') {
    error.value = 'Invalid tour type'
    return
  }

  tourDate.value = date
  tourTime.value = time
  tourId.value = id
  tourType.value = type

  // Format the date for display
  formattedTourDate.value = format(parseISO(date), 'MMMM d, yyyy')

  // Add initial availability check
  checkAvailability()
  
  // Track InitiateBookingForm event
  trackInitiateBookingForm({
    tourId: id,
    tourType: type,
    date: date,
    time: time,
    guestCount: guestCount.value,
    value: totalPrice.value
  });
})

const incrementGuests = async () => {
  // Check if adding one more guest would exceed available spots
  if (guestCount.value < MAX_CAPACITY && guestCount.value < availableSpots.value) {
    guestCount.value++
    if (guestCount.value >= availableSpots.value) {
      error.value = `Maximum capacity reached for this time slot.`
    }
  }
  if (guestCount.value >= 16) {
    showLargeGroupMessage.value = true
  }
}

const decrementGuests = () => {
  if (guestCount.value > 1) {
    guestCount.value--
    if (guestCount.value < availableSpots.value) {
      error.value = null
    }
    if (guestCount.value < 16) {
      showLargeGroupMessage.value = false
    }
  }
}

const totalPrice = computed(() => {
  const basePrice = tourType.value === 'Tour and Tasting' ? 4000 : 2500
  return guestCount.value * basePrice
})

const generateBookingReference = () => {
  const dateStr = format(new Date(tourDate.value), 'yyMMdd')
  const timeStr = tourTime.value.replace(/[:\s]/g, '')
  const nameStr = lastName.value.slice(0, 3).toUpperCase()
  return `${nameStr}${dateStr}${timeStr}`
}


const saveToDatabase = async (bookingData) => {
  try {
    const dateStr = format(new Date(bookingData.date), 'yyyy-MM-dd')
    if (import.meta.env.DEV) {
      console.log('Saving booking:', {
        date: dateStr,
        reference: bookingData.reference,
        timestamp: new Date().toISOString(),
        endpoint: '/bookings'
      })
    }
    
    // Use the correct endpoint path without /api prefix
    const response = await api.post('/bookings', bookingData)
    
    if (import.meta.env.DEV) {
      console.log('Booking saved successfully:', {
        date: dateStr,
        reference: response.data.reference,
        timestamp: new Date().toISOString(),
        data: response.data
      })
    }
    
    return response.data
  } catch (error) {
    console.error('Error saving booking:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      timestamp: new Date().toISOString(),
      endpoint: '/bookings',
      fullError: error
    })
    throw error
  }
}

const sendConfirmationEmail = async (bookingData) => {
  const maxRetries = 3;
  let attempt = 0;
  
  while (attempt < maxRetries) {
    try {
      console.log('Sending confirmation email:', {
        timestamp: new Date().toISOString(),
        email: bookingData.email,
        attempt: attempt + 1
      })
      
      const response = await api.post('/send-confirmation', {
        ...bookingData,
        timestamp: new Date().toISOString()
      })
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to send confirmation email')
      }
      
      console.log('Confirmation email sent:', {
        timestamp: new Date().toISOString(),
        status: response.status,
        data: response.data
      })
      
      return response.data
    } catch (error) {
      attempt++
      console.error('Email sending error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        timestamp: new Date().toISOString(),
        attempt
      })
      
      // If we've tried max times or it's an auth error, stop retrying
      if (attempt >= maxRetries || error.response?.data?.message === 'Unauthorized') {
        // Log the final failure but don't throw - we don't want to block the booking process
        console.warn('Failed to send confirmation email after', attempt, 'attempts')
        return { success: false, error: error.message }
      }
      
      // Wait before retrying (exponential backoff)
      await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000))
    }
  }
}

const isSubmitting = ref(false)

const submitBooking = async () => {
  if (isSubmitting.value) return
  
  try {
    isSubmitting.value = true
    
    // Check availability one final time before submitting
    const isAvailable = await checkAvailability()
    if (!isAvailable) {
      throw new Error('This time slot is no longer available for the selected number of guests.')
    }
    
    const bookingReference = generateBookingReference()
    const bookingData = {
      reference: bookingReference,
      tourId: tourId.value,
      tourType: tourType.value,
      date: tourDate.value,
      time: tourTime.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      preferredLanguage: preferredLanguage.value,
      guestCount: guestCount.value,
      totalPrice: totalPrice.value
    }
    
    // Track booking attempt with Facebook Pixel
    trackInitiateCheckout(
      {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value
      }, 
      {
        content_type: 'tour',
        content_name: tourType.value,
        content_ids: [tourId.value],
        currency: 'VUV',
        value: totalPrice.value,
        num_items: guestCount.value
      }
    );
    
    // Save to database first
    const savedBooking = await saveToDatabase(bookingData)
    
    // Send confirmation email in parallel with navigation
    sendConfirmationEmail(bookingData).catch(error => {
      console.warn('Email sending failed but booking was successful:', error)
    })

    // Track successful booking
    if (window.gtag) {
      gtag('event', 'purchase', {
        transaction_id: bookingReference,
        value: totalPrice.value,
        currency: 'VUV',
        tour_type: tourType.value,
        time_slot: tourTime.value,
        booking_status: 'confirmed',
        preferred_language: preferredLanguage.value,
        items: [{
          item_name: tourType.value,
          price: tourType.value === 'Tour and Tasting' ? 4000 : 2500,
          quantity: guestCount.value
        }]
      })
    }

    // Navigate to confirmation page immediately
    router.push({
      path: '/tours/confirmed',
      query: { ref: bookingReference }
    })
  } catch (e) {
    // Track booking error
    if (window.gtag) {
      gtag('event', 'booking_error', {
        event_category: 'Errors',
        event_label: e.message,
        error_type: e.name
      })
    }
    
    error.value = e.response?.data?.message || e.message || 'Failed to submit booking'
    console.error('Booking submission error:', {
      message: e.message,
      response: e.response?.data,
      status: e.response?.status,
      stack: e.stack,
      type: e.constructor.name,
      endpoint: '/bookings'
    })
  } finally {
    isSubmitting.value = false
  }
}

// Add this computed property after other computed properties
const isFrenchBlocked = computed(() => {
  const [time, period] = tourTime.value.split(' ')
  const hour = parseInt(time)
  return (period === 'AM' && hour === 12) || 
         (period === 'PM' && (hour === 12 || hour === 1 || hour === 1))
})
</script> 