<template>
  <div class="w-full min-h-screen flex flex-col">
    <MainNav :inverted="true" />
    <section class="w-full relative">
      <img 
        src="/image/83islands_location.jpg" 
        alt="83 Islands Distillery" 
        class="w-full h-[800px] object-cover object-[right_top] md:object-center"
      />
      
      <!-- Text Overlay -->
      <div class="mx-auto absolute mt-36 inset-0 z-20 w-full p-4 lg:p-36 flex flex-col items-center lg:items-start">
        <h1 class="text-white font-maax text-4xl uppercase lg:text-6xl font-bold text-center lg:text-left max-w-3xl leading-tight drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)]">
          Get in touch with us
        </h1>
      </div>

      <!-- Double Wave -->
      <div class="absolute -bottom-1 left-0 right-0 w-full overflow-hidden">
        <svg viewBox="0 0 1200 120" preserveAspectRatio="none" class="w-full h-20 text-white">
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                fill="currentColor" class="opacity-25"/>
          <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                fill="currentColor"/>
        </svg>
      </div>
    </section>

    <main class="flex-grow w-full bg-white text-black">
      <div class="container mx-auto p-4">
        <div class="max-w-7xl mx-auto">
          <div class="grid md:grid-cols-2 gap-12">
            <!-- Contact Information -->
            <div class="lg:pr-12">
              <h2 class="text-4xl font-maax font-bold uppercase text-black pb-4">Contact Us</h2>
              
              <div class="space-y-8">
                <div>
                  <h3 class="text-2xl font-maax font-bold text-black mb-4">Visit Us</h3>
                  <p class="font-maax text-xl text-black">
                    83 Islands Distillery<br/>
                    1276 Stella Mare<br/>
                    Port Vila, PO1276<br/>
                    Vanuatu
                  </p>
                </div>

                <div>
                  <h3 class="text-2xl font-maax font-bold text-black mb-4">Contact Details</h3>
                  <p class="font-maax text-xl">
                    Phone: +678 755 0083<br/>
                    Email: <a href="mailto:contact@83islands.com">contact@83islands.com</a>
                  </p>
                </div>

                <div>
                  <h3 class="text-2xl font-maax font-bold text-black mb-4">Opening Hours</h3>
                  <p class="font-maax text-xl">
                    Monday - Friday: 8AM - 5PM<br/>
                    Saturday: 12AM - 6PM<br/>
                    Closed on Sundays and Public Holidays
                  </p>
                </div>

                <div>
                  <h3 class="text-2xl font-maax font-bold text-black mb-4">Location Code</h3>
                  <p class="font-maax text-xl">
                    7928+4R Port Vila, Vanuatu<br/>
                  </p>
                </div>
              </div>
            </div>

            <!-- Google Maps -->
            <div class="h-[600px] md:h-full bg-gray-100 md:col-span-1 md:col-start-2 md:row-span-2">
              <iframe 
                :src="mapUrl"
                width="100%" 
                height="100%" 
                style="border:0;" 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
          </div>

          <!-- Payment Methods -->
          <div class="mt-12">
            <h3 class="text-2xl font-maax font-bold text-black mb-4">Payment Methods Accepted</h3>
            <ul class="font-maax text-xl space-y-2">
              <li>Cash (VATU, AUD)</li>
              <li>Credit card (Master Cards & Visa)</li>
              <li>Local Cards (ANZ and BRED)</li>
              <li>Cheque</li>
              <li>Bred Connect</li>
            </ul>
          </div>

          <!-- Event Booking Form -->
          <div id="event-booking" class="mt-16 border-t pt-12">
            <h2 class="text-4xl font-maax font-bold uppercase text-black mb-8">Event Enquiry</h2>
            <form @submit.prevent="handleEventSubmit" class="space-y-6">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <!-- Name -->
                <div>
                  <label for="name" class="block font-maax text-lg font-medium text-gray-700">Name *</label>
                  <input
                    type="text"
                    id="name"
                    v-model="eventForm.name"
                    required
                    class="mt-1 block w-full rounded-md bg-gray-50 border border-gray-300 px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>

                <!-- Business -->
                <div>
                  <label for="business" class="block font-maax text-lg font-medium text-gray-700">Business (Optional)</label>
                  <input
                    type="text"
                    id="business"
                    v-model="eventForm.business"
                    class="mt-1 block w-full rounded-md bg-gray-50 border border-gray-300 px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>

                <!-- Email -->
                <div>
                  <label for="email" class="block font-maax text-lg font-medium text-gray-700">Email *</label>
                  <input
                    type="email"
                    id="email"
                    v-model="eventForm.email"
                    required
                    class="mt-1 block w-full rounded-md bg-gray-50 border border-gray-300 px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>

                <!-- Phone -->
                <div>
                  <label for="phone" class="block font-maax text-lg font-medium text-gray-700">Phone (Optional)</label>
                  <input
                    type="tel"
                    id="phone"
                    v-model="eventForm.phone"
                    class="mt-1 block w-full rounded-md bg-gray-50 border border-gray-300 px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>

                <!-- Event Name -->
                <div>
                  <label for="eventName" class="block font-maax text-lg font-medium text-gray-700">Event Name/Reason *</label>
                  <input
                    type="text"
                    id="eventName"
                    v-model="eventForm.eventName"
                    required
                    class="mt-1 block w-full rounded-md bg-gray-50 border border-gray-300 px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>

                <!-- Number of People -->
                <div>
                  <label for="peopleCount" class="block font-maax text-lg font-medium text-gray-700">Number of People *</label>
                  <input
                    type="text"
                    id="peopleCount"
                    v-model="eventForm.peopleCount"
                    placeholder="e.g., 50-100"
                    required
                    class="mt-1 block w-full rounded-md bg-gray-50 border border-gray-300 px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </div>

              <!-- Catering Options -->
              <div>
                <label class="block font-maax text-lg font-medium text-gray-700">Catering Preference *</label>
                <div class="mt-2 space-y-2 bg-gray-50 border border-gray-300 rounded-md p-4">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="eventForm.catering"
                      value="no"
                      required
                      class="form-radio text-blue-600 focus:ring-blue-500"
                    />
                    <span class="ml-2 text-gray-900">No Catering</span>
                  </label>
                  <br />
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="eventForm.catering"
                      value="required"
                      required
                      class="form-radio text-blue-600 focus:ring-blue-500"
                    />
                    <span class="ml-2 text-gray-900">Catering Required</span>
                  </label>
                  <br />
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="eventForm.catering"
                      value="own"
                      required
                      class="form-radio text-blue-600 focus:ring-blue-500"
                    />
                    <span class="ml-2 text-gray-900">I'll bring my own food/Catering</span>
                  </label>
                </div>
              </div>

              <!-- Description -->
              <div>
                <label for="description" class="block font-maax text-lg font-medium text-gray-700">Event Description *</label>
                <textarea
                  id="description"
                  v-model="eventForm.description"
                  rows="4"
                  required
                  class="mt-1 block w-full rounded-md bg-gray-50 border border-gray-300 px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                ></textarea>
              </div>

              <div class="flex justify-end">
                <button
                  type="submit"
                  class="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  :disabled="isSubmitting"
                >
                  {{ isSubmitting ? 'Sending...' : 'Submit Request' }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
    <Footer class="relative" />
  </div>
</template>

<script setup>
import MainNav from '../components/MainNav.vue'
import Footer from '../components/Footer.vue'
import { ref, onMounted, nextTick, computed } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'

const isSubmitting = ref(false)
const eventForm = ref({
  name: '',
  business: '',
  email: '',
  phone: '',
  eventName: '',
  peopleCount: '',
  catering: '',
  description: ''
})

const route = useRoute()

// Add axios configuration with debug logs
const api = axios.create({
  baseURL: import.meta.env.DEV 
    ? '' // Empty string because we're using Vite's proxy in development
    : import.meta.env.VITE_API_URL?.replace(/\/+$/, ''),
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

// Add debugging log only in development
if (import.meta.env.DEV) {
  console.log('API Configuration:', {
    baseURL: api.defaults.baseURL,
    mode: import.meta.env.MODE,
    isDev: import.meta.env.DEV,
    apiUrl: import.meta.env.VITE_API_URL,
    timestamp: new Date().toISOString()
  })
}

const mapUrl = computed(() => {
  return `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&q=83+Islands+Distillery,Port+Vila,Vanuatu`
})

const handleEventSubmit = async () => {
  if (isSubmitting.value) return

  try {
    isSubmitting.value = true
    
    const eventData = {
      name: eventForm.value.name,
      business: eventForm.value.business,
      email: eventForm.value.email,
      phone: eventForm.value.phone,
      eventName: eventForm.value.eventName,
      peopleCount: eventForm.value.peopleCount,
      catering: eventForm.value.catering,
      description: eventForm.value.description,
      to: 'events@83islands.com'
    }

    // Debug log before making the request
    console.log('Attempting to send event request:', {
      url: '/api/send-event-email',
      baseURL: api.defaults.baseURL,
      method: 'POST',
      headers: api.defaults.headers,
      data: eventData,
      timestamp: new Date().toISOString()
    })

    const response = await api.post('/api/send-event-email', eventData)
    
    // Debug log after successful response
    console.log('Event request response:', {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
      headers: response.headers,
      timestamp: new Date().toISOString()
    })

    if (!response.data.success) {
      throw new Error(response.data.message || 'Failed to send event request')
    }

    alert('Thank you! We will get back to you shortly with options and a quote.')
    
    // Reset form
    eventForm.value = {
      name: '',
      business: '',
      email: '',
      phone: '',
      eventName: '',
      peopleCount: '',
      catering: '',
      description: ''
    }

  } catch (error) {
    // Enhanced error logging
    console.error('Error sending email:', {
      message: error.message,
      response: {
        data: error.response?.data,
        status: error.response?.status,
        statusText: error.response?.statusText,
        headers: error.response?.headers
      },
      request: {
        url: error.config?.url,
        baseURL: error.config?.baseURL,
        method: error.config?.method,
        headers: error.config?.headers
      },
      timestamp: new Date().toISOString()
    })
    alert('Sorry, there was an error sending your request. Please try again later.')
  } finally {
    isSubmitting.value = false
  }
}

onMounted(async () => {
  // Handle hash navigation
  if (route.hash === '#event-booking') {
    await nextTick()
    const element = document.getElementById('event-booking')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
})
</script> 