<template>
    <div class="w-full min-h-screen flex flex-col">
        <header 
            v-show="showHeader" 
            class="fixed top-0 left-0 right-0 z-30 px-4 py-1 bg-transparent transition-all duration-300"
        >
            <div class="container mx-auto flex justify-between items-center">
                <!-- Left-aligned SVG icon -->
          <router-link to="/">
            <img 
              src="/image/83-icon.svg" 
              alt="83 Icon" 
              class="w-16 h-16 [filter:brightness(0)_invert(1)]"
            />
          </router-link>
  
          <nav class="flex items-center space-x-3 lg:space-x-8">
            <router-link to="/home" class="font-maax text-xs lg:text-sm uppercase hover:text-gray-300">Discover</router-link>
            <router-link to="/spirit" class="font-maax text-xs lg:text-sm uppercase hover:text-gray-300">The Spirit</router-link>
            <router-link to="/tours" class="font-maax text-xs lg:text-sm uppercase hover:text-gray-300">Tours & Events</router-link>
            <a href="#" class="font-maax text-xs lg:text-sm uppercase hover:text-gray-300">News</a>
            <router-link to="/community" class="font-maax text-xs lg:text-sm uppercase hover:text-gray-300">Community</router-link>
            <a href="#" class="font-maax text-xs lg:text-sm uppercase hover:text-gray-300">Where to Buy</a>
            <a href="#" class="font-maax text-xs lg:text-sm uppercase hover:text-gray-300">Contact Us</a>
          </nav>
        </div>
      </header>
      <section class="w-full relative">
        <img 
                src="/image/TannaMay2021-print-268.jpg" 
                alt="83 Islands Jungle" 
                class="w-full h-[800px] object-cover object-[right_top] md:object-center"
            />
        
        <!-- Text Overlay -->
        <div class="absolute mt-36 inset-0 z-20 w-full p-4 lg:p-12 flex flex-col items-center lg:items-start pointer-events-none">
            <h1 class="text-white font-maax text-4xl uppercase lg:text-6xl font-bold text-center lg:text-left max-w-2xl leading-tight drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)]">
            Step beyond the ordinary and into a world where nature leads, and time lingers.
          </h1>
        </div>

<!--  Double Wave -->
            <div class="absolute -bottom-1 left-0 right-0 w-full overflow-hidden">
                <svg viewBox="0 0 1200 120" preserveAspectRatio="none" class="w-full h-20 text-white">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                          fill="currentColor" class="opacity-25"/>
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                          fill="currentColor"/>
                </svg>
            </div>



      <!-- SVG Pattern Overlay -->
<!--       <div 
    class="absolute bottom-8 left-0 right-0 z-10 w-full h-24 bg-repeat-x [filter:brightness(0)_invert(1)] "
    style="background-image: url('/image/83-Teouma-web.svg');"
>
</div> -->
        </section>
      <main class="flex-grow w-full bg-white">
        <div class="container mx-auto p-4">
          <h2 class="text-xl">Welcome to the Discover Page</h2>
          <p>This is where you can add more content about your discovery.</p>

          <p class="text-white p-4 font-maax text-large uppercase mt-6 max-w-2xl font-medium text-center lg:text-left drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)]">
            Let 83 Islands take you to a world unspoiled by haste, where every drop reflects the rhythm of the sun and the whispers of the past. This is more than rum—it’s a bridge to the untouched, where nature reigns and community thrives. Embrace the spirit of Vanuatu, a land that invites you to slow down, breathe, and rediscover what it means to truly belong to the earth.
          </p>
        </div>
              <!-- SVG Pattern Overlay -->
 <div 
    class=" bottom-8 left-0 right-0 z-10 w-full h-24 bg-repeat-x "
    style="background-image: url('/image/83-Teouma-web.svg');">
</div>
      </main>
      <Footer class="relative" />
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import Footer from '../components/Footer.vue';

  const showHeader = ref(true);
    let lastScrollPosition = 0;

    const handleScroll = () => {
        const currentScrollPosition = window.scrollY;
        
        // Show header when scrolling up or at top
        showHeader.value = 
            currentScrollPosition < lastScrollPosition || 
            currentScrollPosition < 50;
        
        lastScrollPosition = currentScrollPosition;
    };

    // Add and remove scroll listener
    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });
</script>


<style>
/* Optional: Add smooth transition for header */
header {
    transition: transform 0.3s ease;
}
</style>
