<template>
  <div class="w-full min-h-screen">
    <router-view></router-view>
  </div>
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply m-0 p-0;
}

#app {
  @apply w-full min-h-screen;
}
</style>