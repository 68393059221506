import { createRouter, createWebHistory } from 'vue-router'
import Discover from '../views/Discover.vue'
import Intro from '../views/Intro.vue'  // Import Intro component
import Community from '../views/Community.vue'
import Spirits from '../views/Spirits.vue'
import Tours from '../views/Tours.vue'
import BookingConfirmation from '../views/BookingConfirmation.vue'
import ToursConfirm from '../views/ToursConfirm.vue'
import ToursConfirmed from '../views/ToursConfirmed.vue'
import Buy from '../views/Buy.vue'
import Contact from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro,
    meta: {
      title: '83 Islands Distillery | Vanuatu Rum',
      description: 'Discover authentic Vanuatu rum at 83 Islands Distillery. Experience untouched, extraordinary spirits from the South Pacific. Book distillery tours and tastings.'
    }
  },
  {
    path: '/discover',
    name: 'Discover',
    component: Discover,
    meta: {
      title: 'Discover | 83 Islands Distillery'
    }
  },
  {
    path: '/spirits',
    name: 'The Spirits',
    component: Spirits,
    meta: {
      title: 'The Spirits | 83 Islands Distillery'
    }
  },
  {
    path: '/tours',
    name: 'Tours',
    component: Tours,
    meta: {
      title: 'Tours & Events | 83 Islands Distillery',
      description: 'Book a tour at 83 Islands Distillery. Experience our world-class rum making process and enjoy guided tastings in the best-looking bar in Port Vila.',
      step: 1,
      funnel: 'tour_booking'
    }
  },
  {
    path: '/community',
    name: 'Community',
    component: Community,
    meta: {
      title: 'Home | 83 Islands Distillery'
    }
  },
  {
    path: '/tours/booking/:tourId/:guests/:date',
    name: 'completeBooking',
    component: BookingConfirmation
  },
  {
    path: '/tours/confirm',
    name: 'toursConfirm',
    component: ToursConfirm,
    meta: {
      title: 'Confirm Tour Booking | 83 Islands Distillery',
      description: 'Complete your booking for a tour at 83 Islands Distillery. Choose your preferred time and number of guests.',
      step: 2,
      funnel: 'tour_booking'
    }
  },
  {
    path: '/tours/confirmed',
    name: 'toursConfirmed',
    component: ToursConfirmed,
    meta: {
      title: 'Booking Confirmed | 83 Islands Distillery',
      description: 'Your tour booking at 83 Islands Distillery has been confirmed. View your booking details and confirmation.',
      step: 3,
      funnel: 'tour_booking'
    }
  },
  {
    path: '/buy',
    name: 'buy',
    component: Buy
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/:pathMatch(.*)*',
    beforeEnter: (to, from, next) => {
      // Check if the path is an API request
      if (to.path.startsWith('/api/')) {
        // Let API requests pass through
        next(false);
        return;
      }
      
      // Check if the path contains a file extension (images, assets, etc.)
      if (to.path.match(/\.(png|jpe?g|gif|svg|ico|pdf|css|js|mp4|webp|woff|woff2|ttf|eot)$/i)) {
        // If it's a file, let the request pass through
        next(false);
        return;
      }
      
      // If it's not an API request or a file, redirect to Intro
      next({ name: 'Intro' });
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the route has a hash, scroll to that element
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
    
    // Otherwise scroll to top
    return { 
      top: 0,
      behavior: 'instant'
    }
  }
})

// Enhanced title and meta update logic
router.beforeEach((to, from, next) => {
  // Update title
  document.title = to.meta.title || '83 Islands Distillery | Vanuatu Rum'
  
  // Update meta description
  let descriptionMeta = document.querySelector('meta[name="description"]')
  if (!descriptionMeta) {
    descriptionMeta = document.createElement('meta')
    descriptionMeta.name = 'description'
    document.head.appendChild(descriptionMeta)
  }
  descriptionMeta.content = to.meta.description || 'Discover authentic Vanuatu rum at 83 Islands Distillery. Experience untouched, extraordinary spirits from the South Pacific.'

  // Track page view and funnel step if applicable
  if (window.gtag) {
    gtag('event', 'page_view', {
      page_title: to.meta.title,
      page_path: to.fullPath,
      page_location: window.location.href
    })

    // Track funnel step if present
    if (to.meta.funnel && to.meta.step) {
      gtag('event', 'tour_booking_step', {
        funnel: to.meta.funnel,
        step: to.meta.step,
        step_name: to.name
      })
    }

    // Add these to your existing GA4 configuration
    gtag('config', 'G-RMV17CXZRM', {
      custom_map: {
        'dimension1': 'tour_type',
        'dimension2': 'time_slot',
        'dimension3': 'booking_status',
        'dimension4': 'preferred_language'
      }
    });
  }

  next()
})

export default router