<template>
  <div class="w-full min-h-screen flex flex-col">
      <MainNav />
      <section class="w-full relative">
    <!-- Video Background -->
    <div class="relative h-[800px] overflow-hidden">
        <video 
            autoplay 
            loop 
            muted 
            playsinline 
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-full min-h-full object-cover"
        >
            <source src="/video/83islands-tours.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
        
        <!-- Text Overlay -->
        <div class="mx-auto absolute mt-36 inset-0 z-20 w-full p-4 lg:p-36 flex flex-col items-center lg:items-start">
            <h1 class="text-white font-maax text-4xl uppercase lg:text-6xl font-bold text-center lg:text-left max-w-3xl leading-tight drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)]">
              83 Islands Tour & Tasting - The Highest Rated Tour in Port Vila
          </h1>

          <!-- Buttons -->
      <div class="flex flex-col sm:flex-row gap-4 mt-8 lg:mt-12">
        <a 
          href="#calendar-section" 
          class="font-maax px-8 py-3 uppercase font-bold transition-all rounded-lg drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] border-2 text-white hover:bg-white hover:text-black hover:border-white text-center sm:text-left"
        >
          Book Tour
      </a>
        <router-link 
          to="/contact?from=event-booking#event-booking" 
          class="font-maax px-8 py-3 uppercase font-bold transition-all rounded-lg drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] border-2 text-white hover:bg-white hover:text-black hover:border-white text-center sm:text-left"
        >
          Event Enquiry
        </router-link>

        <!-- <router-link to="/Home" class="font-maax px-8 py-3 uppercase font-bold transition-all rounded-full drop-shadow-[0_5px_5px_rgba(0,0,0,0.3)] border-2 hover:border-white hover:text-white" style="border-image: linear-gradient(45deg, #F6D993, #A87539 80%) 1; background: -webkit-linear-gradient(45deg, #F6D993, #A87539 80%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; transition: border-image 0.3s ease, -webkit-text-fill-color 0.3s ease;">
      Discover
    </router-link> -->
       
      </div>
        </div>

<!--  Double Wave -->
            <div class="absolute -bottom-1 left-0 right-0 w-full overflow-hidden">
                <svg viewBox="0 0 1200 120" preserveAspectRatio="none" class="w-full h-20 text-white">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                          fill="currentColor" class="opacity-25"/>
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" 
                          fill="currentColor"/>
                </svg>
            </div>
        </section>

       

        <main class="flex-grow w-full bg-white">
        <div class="container mx-auto p-4">
    <!-- Create a flex container for the split layout -->
    <div class="flex flex-col gap-8">
      <!-- Main content - full width -->
      <div class="w-full">
        <h2 class="text-4xl font-maax font-bold uppercase text-black pb-4">Join Us for an Unforgettable Experience.</h2>

        <p class="font-maax text-black text-2xl pb-8">Step into the heart of 83 Islands Distillery and immerse yourself in the craft, tradition, and untamed beauty of Vanuatu. From guided tastings to exclusive events, experience our world-class rums in the best-looking bar in Port Vila—a space designed to reflect the raw elegance of the islands.</p>

        <p class="font-maax text-black text-2xl pb-8">
          Go beyond the bottle and explore the flavors, stories, and rhythms that define our spirit. Whether you're a seasoned enthusiast or new to rum, an unforgettable journey awaits.
        </p>
        <p class="font-maax text-black text-2xl">
        Book your experience today and raise a glass to the extraordinary.
        </p>
      </div>

      <!-- Important Information Bar - full width -->
      <div class="w-full mt-8">
        <div class="bg-black p-8 rounded-lg text-white">
          <div class="grid grid-cols-1 md:grid-cols-4 gap-6">
            <!-- Date & Status -->
            <div class="border-b md:border-b-0 md:border-r border-gray-700 pb-4 md:pb-0 md:pr-6">
              <div class="text-sm text-gray-400">Today's Date</div>
              <div class="text-2xl font-bold mb-4">
                {{ format(new Date(), 'MMMM d, yyyy') }}
              </div>
              <div>
                <div class="flex items-center">
                  <div 
                    class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                    :class="isOpenToday ? 'bg-green-500' : 'bg-red-500'"
                  ></div>
                  <div 
                    class="text-lg font-semibold"
                    :class="isOpenToday ? 'text-green-500' : 'text-red-500'"
                  >
                    {{ isOpenToday ? 'Open' : 'Closed' }}
                  </div>
                </div>
                <div class="text-xs text-gray-400 mt-1 whitespace-pre-line ml-5">
                  {{ isOpenToday ? '8:00 AM - 5:00 PM' : closedReason }}
                </div>
              </div>
            </div>

            <!-- Tour Options -->
            <div class="border-b md:border-b-0 md:border-r border-gray-700 pb-4 md:pb-0 md:pr-6">
              <h4 class="text-lg font-semibold text-gold mb-3">Tour Options</h4>
              <div class="space-y-2 text-gray-400">
                <p class="text-white font-bold ">Distillery Experience: 4,000 VT</p>
                <p class="text-xs text-gray-400">Guided journey through our artisanal rum-making process, from fresh sugarcane to the final spirit, with an exclusive behind-the-scenes look at fermentation, distillation, and barrel aging.</p>
                <p class="text-white font-bold">Cellar Door Tasting: 2,500 VT</p>
                <p class="text-xs text-gray-400"> Curated tasting of our premium rums, each sip revealing the island's rich terroir and craftsmanship.</p>
              </div>
            </div>

            <!-- Important Info -->
            <div class="border-b md:border-b-0 md:border-r border-gray-700 pb-4 md:pb-0 md:pr-6">
              <h4 class="text-lg font-semibold text-gold mb-3">Important Info</h4>
              <ul class="space-y-2 text-sm">
                <li class="text-gray-300">• Maximum 16 people per group</li>
                <li class="text-gray-300">• Free cancellation</li>
                <li class="text-gray-300">• Accessibility access available</li>
                <li class="text-gray-300">• French tours subject to availability</li>
                <li class="text-gray-300">• 18+ for tasting. Non-alcoholic drinks available</li>
                <li class="text-gray-300">• Closed on Sundays and Public Holidays</li>
              </ul>
            </div>

            <!-- Safety Notice -->
            <div>
              <h4 class="text-lg font-semibold text-red-400 mb-3">Safety Notice</h4>
              <ul class="space-y-2 text-sm">
                <li class="text-gray-300">• Active working distillery</li>
                <li class="text-gray-300">• Hazards to be expected</li>
                <li class="text-gray-300 font-bold">• No smoking on site</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Calendar and Tour Picker Container -->
  <div class="relative">
    <!-- Scroll target with offset -->
    <div id="calendar-section" class="absolute" style="top: -50px"></div>
    
    <!-- Actual calendar content -->
    <div class="container mx-auto w-full flex flex-col lg:flex-row gap-8 p-4">
      <!-- Calendar Section -->
      <section class="relative bg-white w-full lg:w-3/5">
        <div class="relative mx-auto h-full py-8 sm:py-12 lg:px-8 lg:py-12">
          <div class="h-full w-full">
            <div class="h-full flex flex-col">
              <h2 class="text-2xl font-semibold leading-6 text-gray-900">Select a Date</h2>
              <p class="text-sm mb-4">Book up to 3 months in advance</p>
              <div class="flex items-center">
                <h2 class="flex-auto text-base font-semibold leading-6 text-gray-900">
                  <time :datetime="currentMonth">{{ formattedCurrentMonth }}</time>
                </h2>
                <button 
                  type="button" 
                  class="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 bg-transparent" 
                  @click="prevMonth"
                  :disabled="isBefore(startOfMonth(subMonths(currentMonth, 1)), startOfMonth(today))"
                >
                  <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                  <span class="">Previous month</span>
                </button>
                <button 
                  type="button" 
                  class="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 bg-transparent" 
                  @click="nextMonth"
                  :disabled="isAfter(startOfMonth(addMonths(currentMonth, 1)), maxFutureMonth)"
                >
                  <span class="">Next month</span>
                  <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div class="mt-10 grid grid-cols-7 text-center text-2xl leading-6 text-gray-500">
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
                <div>S</div>
              </div>
              <div class="mt-8 grid grid-cols-7 text-xl">
                <div 
                  v-for="(day, dayIdx) in days" 
                  :key="day.date" 
                  :class="[
                    dayIdx > 6 && 'border-t border-gray-200',
                    'py-4'
                  ]"
                >
                  <button 
                    v-if="!day.isEmpty"
                    type="button" 
                    :class="[
                      'mx-auto flex flex-col h-12 w-12 items-center justify-center rounded-full focus:outline-none',
                      (day.holiday || getDay(parseISO(day.date)) === 0) ? 'bg-red-100' : 'bg-transparent',
                      day.isPast ? 'text-gray-400 cursor-not-allowed' :
                      day.isSelected ? '!bg-gold !text-black' :
                      day.isToday ? '!bg-gray-400 !text-black' :
                      day.isCurrentMonth ? 'text-gray-900' : 'text-gray-400',
                      !day.isPast && !day.isSelected && !day.holiday && getDay(parseISO(day.date)) !== 0 && 'hover:bg-gray-100',
                      (day.isSelected || day.isToday) && 'font-semibold'
                    ]" 
                    @click="!day.isPast && !day.holiday && getDay(parseISO(day.date)) !== 0 && selectDate(day.date)" 
                    :disabled="day.isPast || day.holiday || getDay(parseISO(day.date)) === 0"
                    :title="day.holiday || (getDay(parseISO(day.date)) === 0 ? 'Closed on Sundays' : '')"
                  >
                    <time v-if="!day.isEmpty" :datetime="day.date">
                      {{ day.isEmpty ? '' : parseInt(day.date.split('-')[2]) }}
                    </time>
                  </button>
                </div>
              </div>

              <!-- Upcoming Holidays Section -->
              <div class="hidden lg:block mt-8 border-t border-gray-200 pt-8">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <!-- Holidays Section -->
                  <div>
                    <h3 class="text-lg font-semibold text-gray-900 mb-4">Upcoming Holidays</h3>
                    <div class="space-y-2">
                      <div v-if="holidays.length === 0" class="text-gray-500">
                        Loading holidays...
                      </div>
                      <div 
                        v-for="holiday in upcomingHolidays" 
                        :key="holiday.date" 
                        class="flex items-center space-x-4 py-2"
                      >
                        <div class="flex-shrink-0 w-24">
                          <span class="text-sm font-medium text-gray-500">
                            {{ format(parseISO(holiday.date), 'MMM dd') }}
                          </span>
                        </div>
                        <div class="flex-grow">
                          <span class="text-sm text-gray-900">{{ holiday.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Events Section -->
                  <div>
                    <h3 class="text-lg font-semibold text-gray-900 mb-4">Events</h3>
                    <div class="space-y-2">
                      <div 
                        v-for="event in upcoming83IslandsNights" 
                        :key="event.date" 
                        class="flex items-center space-x-4 py-2"
                      >
                        <div class="flex-shrink-0 w-24">
                          <span class="text-sm font-medium text-gray-500">
                            {{ format(parseISO(event.date), 'MMM dd') }}
                          </span>
                        </div>
                        <div class="flex-grow">
                          <span class="text-sm text-gray-900">83 Islands Nights</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Tour Picker Section -->
      <section id="tour-picker-section" class="relative w-full lg:w-2/5">
        <div class="bg-black p-8 rounded-lg text-white">
          <div class="border-b border-gray-700 pb-4 mb-4">
            <h2 class="text-2xl font-semibold leading-6 text-gray-100">Available Tours for {{ formattedSelectedDate }}</h2>
            <p v-if="isWeekend" class="text-sm text-gold mt-2">
              On Saturday, tours are only available from 12 PM onwards.
            </p>
            <p class="text-sm pt-2 leading-6 text-gray-400">Click on a time to proceed with booking</p>
          </div>

          <!-- Add Tour Type Filter -->
          <div class="flex space-x-2 mb-4">
            <button
              v-for="type in tourTypes"
              :key="type"
              @click="selectedTourType = type"
              :class="[
                'px-4 py-2 rounded-lg text-sm font-medium transition-all duration-300',
                selectedTourType === type
                  ? 'bg-gold text-black'
                  : 'bg-gray-800 text-white hover:bg-gray-700'
              ]"
            >
              {{ type }}
            </button>
          </div>

          <div v-if="isSunday || isSelectedDateHoliday" class="text-center text-red-400 mt-4">
            Sorry, we are closed on Sundays and public holidays.
          </div>
          
          <ol v-else class="mt-4 space-y-4 text-sm leading-6 text-gray-400">
            <li v-for="tour in filteredToursByType.filter(tour => !isPastTime(tour.time))" 
                :key="tour.id" 
                @click="tour.isAvailable && selectTourTime(tour.time, tour.id)"
                :class="[
                  'group rounded-xl px-4 py-2 border-2 transition-all duration-300',
                  tour.type === 'Tasting Only' ? 'bg-gray-900' : 'bg-gray-800',
                  tour.isAvailable 
                    ? 'hover:bg-opacity-75 hover:border-gold border-transparent cursor-pointer' 
                    : 'opacity-50 border-transparent cursor-not-allowed'
                ]"
            >
              <div class="flex items-center space-x-4">
                <div class="flex-auto">
                  <p class="mt-0.5 text-xs" :class="getAvailabilityClass(tour)">
                    {{ getAvailabilityText(tour) }}
                  </p>
                  <p class="mt-0.5 text-xl font-bold text-white">
                    {{ tour.time }} - {{ tour.type }}
                  </p>
                  <p class="text-sm text-gray-400">
                    {{ tour.type === 'Tour and Tasting' ? '4,000 VT PP' : '2,500 VT PP' }} - 
                    {{ tour.type === 'Tour and Tasting' ? 'Approx 1 hour duration' : 'Approx 30 min duration' }}
                  </p>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </section>
    </div>
  </div>


  </main> 
      <Footer class="relative" />
    </div>
  </template>
  
  <script setup>
  import { ref, watch, onMounted, computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid'
  import { 
    addMonths, 
    eachDayOfInterval, 
    endOfMonth, 
    format, 
    startOfMonth, 
    subMonths, 
    isValid, 
    isBefore, 
    startOfDay, 
    isAfter, 
    getDay,
    parseISO,
    isToday,
    addDays,
    set
  } from 'date-fns'
  import { formatInTimeZone } from 'date-fns-tz'
  import axios from 'axios'
  import Footer from '../components/Footer.vue'
  import MainNav from '../components/MainNav.vue'
  import { useFacebookPixel } from '../composables/useFacebookPixel'

  const router = useRouter()
  const route = useRoute()

  // Initialize Facebook Pixel
  const { initFacebookPixel, updateConsent, trackViewTours, trackSelectTourDateTime } = useFacebookPixel()

  // Initialize date values
  const today = startOfDay(new Date())
  const currentMonth = ref(today)
  const selectedDate = ref(today)
  const maxFutureMonth = addMonths(today, 3)
  const days = ref([])
  const showHeader = ref(true)
  const tours = ref([])
  const todayBookingsCount = ref(0)
  const selectedTourId = ref(null)
  const holidays = ref([])
  const selectedTourType = ref('All Tours')
  const tourTypes = ['All Tours', 'Tour and Tasting', 'Cellar Door Tasting']
  const bookings = ref({})
  const MAX_CAPACITY = 16
  const TIMEZONE = 'Pacific/Efate'

  // Create an axios instance with base configuration
  const api = axios.create({
    baseURL: import.meta.env.DEV 
      ? '/api' // Use relative path for development with Vite's proxy
      : import.meta.env.VITE_API_URL || '/api',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    // Add timeout for better reliability
    timeout: 10000
  })

  // Safe date formatting function
  const safeFormat = (date, formatString) => {
    try {
      if (!date) return ''
      const validDate = date instanceof Date ? date : new Date(date)
      return isValid(validDate) ? format(validDate, formatString) : ''
    } catch (error) {
      console.error('Date formatting error:', error)
      return ''
    }
  }

  // Computed properties
  const formattedCurrentMonth = computed(() => {
    return safeFormat(currentMonth.value, 'MMMM yyyy')
  })

  const formattedSelectedDate = computed(() => {
    return safeFormat(selectedDate.value, 'MMMM dd, yyyy')
  })

  const isSunday = computed(() => {
    return getDay(selectedDate.value) === 0  // 0 is Sunday
  })

  const isWeekend = computed(() => {
    const day = getDay(selectedDate.value)
    return day === 6  // Only Saturday is treated as weekend for tour times
  })

  const isPastTime = (time) => {
    if (!isToday(selectedDate.value)) return false
    
    const now = new Date()
    const [timeStr, period] = time.split(' ')
    let [hours, minutes] = timeStr.split(':')
    
    // Convert to 24 hour format
    hours = parseInt(hours)
    if (period === 'PM' && hours !== 12) hours += 12
    if (period === 'AM' && hours === 12) hours = 0
    
    // Add 30 minutes buffer before tour time
    const tourTime = new Date()
    tourTime.setHours(hours, parseInt(minutes || 0) - 30, 0)
    
    return isBefore(tourTime, now)
  }

  const getAvailabilityClass = (tour) => {
    if (!tour.isAvailable) return 'text-red-400'
    
    const bookedGuests = getBookedGuestsForTour(tour)
    if (bookedGuests >= 12) return 'text-orange-400'
    if (bookedGuests >= 8) return 'text-yellow-400'
    return 'text-green-600'
  }

  const getBookedGuestsForTour = (tour) => {
    const dateStr = format(selectedDate.value, 'yyyy-MM-dd')
    const localTime = convertToLocalTime(tour.time, dateStr)
    const formattedTime = formatTimeForDisplay(localTime)
    
    const tourBookings = bookings.value[dateStr]?.[formattedTime]?.[tour.type]
    return typeof tourBookings === 'object' ? tourBookings?.count || 0 : tourBookings || 0
  }

  const getAvailabilityText = (tour) => {
    if (!tour.isAvailable) return 'Unavailable'
    
    const dateStr = format(selectedDate.value, 'yyyy-MM-dd')
    const localTime = convertToLocalTime(tour.time, dateStr)
    const formattedTime = formatTimeForDisplay(localTime)
    const tourBookings = bookings.value[dateStr]?.[formattedTime]?.[tour.type]
    const bookedGuests = typeof tourBookings === 'object' ? tourBookings?.count || 0 : tourBookings || 0
    const tourLanguage = typeof tourBookings === 'object' ? tourBookings?.language : null

    // Check if the time is between 11 AM and 2 PM
    const [time, period] = tour.time.split(' ')
    const hour = parseInt(time)
    const is11to2 = (period === 'AM' && hour === 12) || 
                   (period === 'PM' && (hour === 12 || hour === 1 || hour === 1))

    let availabilityText = ''
    if (bookedGuests >= 12) {
      availabilityText = 'Few places left'
    } else if (bookedGuests >= 8) {
      availabilityText = 'Some places left'
    } else {
      // Set default language availability based on time
      availabilityText = is11to2 ? 
        'Available in English' : 
        'Available in English or French'
    }

    // Override with actual booking language if tour is booked
    if (tourLanguage) {
      availabilityText = `In ${tourLanguage}`
    }

    return availabilityText
  }

  const filteredTours = computed(() => {
    // If it's Sunday or a holiday, return empty array
    const selectedDateStr = format(selectedDate.value, 'yyyy-MM-dd')
    const isHoliday = holidays.value.some(holiday => holiday.date === selectedDateStr)
    
    if (isSunday.value || isHoliday) {
      return []
    }
    
    let availableTours = tours.value
    
    // Weekend (Saturday) logic
    if (isWeekend.value) {
      availableTours = tours.value.filter(tour => {
        const [time, period] = tour.time.split(' ')
        const hour = parseInt(time)
        return period === 'PM' || (period === 'AM' && hour === 12)
      })
    }

    // Filter out past times and fully booked tours
    return availableTours.map(tour => {
      const bookedGuests = getBookedGuestsForTour(tour)
      return {
        ...tour,
        isAvailable: !isPastTime(tour.time) && bookedGuests < MAX_CAPACITY
      }
    })
  })

  const filteredToursByType = computed(() => {
    if (selectedTourType.value === 'All Tours') {
      return filteredTours.value
    }
    return filteredTours.value.filter(tour => tour.type === selectedTourType.value)
  })

  // Generate calendar days
  const generateDays = (month) => {
    try {
      const monthDate = new Date(month)
      if (!isValid(monthDate)) {
        console.error('Invalid month provided to generateDays')
        return
      }

      const start = startOfMonth(monthDate)
      const end = endOfMonth(monthDate)
      
      let firstDayOfWeek = getDay(start)
      firstDayOfWeek = firstDayOfWeek === 0 ? 7 : firstDayOfWeek
      const offset = firstDayOfWeek - 1
      
      // Add empty days at the start with unique keys
      const emptyDays = Array(offset).fill(null).map((_, index) => ({
        date: `empty-${index}`, // Unique key for empty days
        isCurrentMonth: false,
        isToday: false,
        isSelected: false,
        isPast: false,
        isEmpty: true // New flag to identify empty days
      }))
      
      // Generate current month days
      const daysInMonth = eachDayOfInterval({ start, end }).map(day => {
        const dateStr = safeFormat(day, 'yyyy-MM-dd')
        const holiday = holidays.value.find(h => h.date === dateStr)
        
        return {
          date: dateStr,
          isCurrentMonth: true,
          isToday: safeFormat(day, 'yyyy-MM-dd') === safeFormat(today, 'yyyy-MM-dd'),
          isSelected: safeFormat(day, 'yyyy-MM-dd') === safeFormat(selectedDate.value, 'yyyy-MM-dd'),
          isPast: isBefore(day, today),
          isEmpty: false,
          holiday: holiday ? holiday.name : null
        }
      })
      
      days.value = [...emptyDays, ...daysInMonth]
    } catch (error) {
      console.error('Error generating days:', error)
      days.value = []
    }
  }

  // Navigation functions
  const prevMonth = () => {
    try {
      const newMonth = subMonths(currentMonth.value, 1)
      if (!isBefore(startOfMonth(newMonth), startOfMonth(today))) {
        currentMonth.value = newMonth
      }
    } catch (error) {
      console.error('Error navigating to previous month:', error)
    }
  }

  const nextMonth = () => {
    try {
      const newMonth = addMonths(currentMonth.value, 1)
      if (!isAfter(startOfMonth(newMonth), maxFutureMonth)) {
        currentMonth.value = newMonth
      }
    } catch (error) {
      console.error('Error navigating to next month:', error)
    }
  }

  const selectDate = (dateString) => {
    try {
      const date = parseISO(dateString)
      if (isValid(date)) {
        selectedDate.value = date
        generateDays(currentMonth.value)
      }
    } catch (error) {
      console.error('Error selecting date:', error)
    }
  }

  const fetchTodayBookingsCount = async () => {
    try {
      todayBookingsCount.value = 0
    } catch (error) {
      console.error('Error fetching bookings count:', error)
      todayBookingsCount.value = 0
    }
  }

  const fetchHolidays = async () => {
    try {
      const calendarId = 'en.vu#holiday@group.v.calendar.google.com'
      const apiKey = import.meta.env.VITE_GOOGLE_CALENDAR_API_KEY
      const timeMin = new Date().toISOString()
      const timeMax = addMonths(new Date(), 3).toISOString()
      
      const response = await axios.get(
        `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendarId)}/events`, {
          params: {
            key: apiKey,
            timeMin,
            timeMax,
            singleEvents: true,
            orderBy: 'startTime'
          }
        }
      )

      holidays.value = response.data.items.map(event => ({
        date: format(new Date(event.start.date), 'yyyy-MM-dd'),
        name: event.summary
      }))
    } catch (error) {
      console.error('Error fetching holidays:', error)
    }
  }

  // Update the convertToLocalTime function
  const convertToLocalTime = (time, date) => {
    const [hourStr, period] = time.split(' ')
    let [hours, minutes] = hourStr.split(':')
    hours = parseInt(hours)
    
    // Convert to 24-hour format
    if (period === 'PM' && hours !== 12) hours += 12
    if (period === 'AM' && hours === 12) hours = 0
    
    // Create a date object with the tour date and time
    const dateObj = new Date(date)
    dateObj.setHours(hours, parseInt(minutes || 0), 0, 0)
    
    // Convert to Vanuatu time using formatInTimeZone
    return new Date(formatInTimeZone(dateObj, TIMEZONE, "yyyy-MM-dd'T'HH:mm:ssXXX"))
  }

  // Update the formatTimeForDisplay function
  const formatTimeForDisplay = (date) => {
    return formatInTimeZone(date, TIMEZONE, 'h:mm a')
  }

  // Update the fetchBookings function
  const fetchBookings = async () => {
    try {
      const dateStr = format(selectedDate.value, 'yyyy-MM-dd')
      console.log('Fetching bookings for date:', dateStr)
      
      const response = await api.get('/api/bookings', {
        params: { date: dateStr }
      })
      
      // Normalize the bookings data
      const normalizedBookings = {}
      if (response.data[dateStr]) {
        Object.entries(response.data[dateStr]).forEach(([time, bookingData]) => {
          // Convert the time to a consistent format
          const localTime = convertToLocalTime(time, dateStr)
          const formattedTime = formatTimeForDisplay(localTime)
          
          normalizedBookings[formattedTime] = bookingData
        })
      }
      
      bookings.value = { [dateStr]: normalizedBookings }
      
      console.log('Bookings fetched successfully:', {
        date: dateStr,
        bookingsCount: Object.keys(normalizedBookings).length,
        timestamp: new Date().toISOString(),
        data: response.data
      })
    } catch (error) {
      console.error('Error fetching bookings:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        timestamp: new Date().toISOString()
      })
      bookings.value = { [format(selectedDate.value, 'yyyy-MM-dd')]: {} }
    }
  }

  // Watch for month changes
  watch(currentMonth, (newVal) => {
    if (newVal) {
      generateDays(newVal)
    }
  }, { immediate: true })

  // Update the watch section to fetch bookings when the date changes
  watch(selectedDate, () => {
    fetchBookings()
  })

  // Add these near the top of the script setup, after imports
  const testDbConnection = async () => {
    try {
      console.log('Testing MongoDB connection...')
      const response = await api.get('/api/db-test')
      console.log('MongoDB Connection Test Result:', {
        ...response.data,
        timestamp: new Date().toISOString()
      })
      return response.data
    } catch (error) {
      const errorDetails = {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        timestamp: new Date().toISOString()
      }
      console.error('MongoDB Connection Test Failed:', errorDetails)
      throw error
    }
  }

  // Update the logEnvironmentInfo function
  const logEnvironmentInfo = () => {
    // Only log in development
    if (import.meta.env.DEV) {
      console.log('Environment:', {
        MODE: import.meta.env.MODE,
        PROD: import.meta.env.PROD,
        DEV: import.meta.env.DEV,
        VITE_API_URL: import.meta.env.VITE_API_URL,
      })

      // Only try to parse URL if it exists
      if (import.meta.env.VITE_API_URL) {
        try {
          const apiUrl = new URL(import.meta.env.VITE_API_URL)
          console.log('Serverless API Port:', apiUrl.port)
          console.log('Full API URL:', apiUrl.toString())
        } catch (error) {
          console.warn('API URL not set or invalid:', import.meta.env.VITE_API_URL)
        }
      }
    }
  }

  // Add this function to check if we're past working hours
  const isPastWorkingHours = () => {
    const now = new Date()
    const endOfWorkDay = set(now, { hours: 17, minutes: 0, seconds: 0 })
    return isAfter(now, endOfWorkDay)
  }

  // Update the onMounted section
  onMounted(async () => {
    try {
      // Initialize Facebook Pixel with implicit consent
      initFacebookPixel();
      updateConsent(true);
      
      // Track page view
      trackViewTours();
      
      // Log environment info
      logEnvironmentInfo()
      
      // If it's past working hours, automatically select next day
      if (isPastWorkingHours()) {
        let nextDay = addDays(today, 1)
        // Skip Sunday if next day is Sunday
        if (getDay(nextDay) === 0) {
          nextDay = addDays(nextDay, 1)
        }
        selectedDate.value = nextDay
        currentMonth.value = nextDay
      }

      // Existing functionality
      generateDays(currentMonth.value)
      fetchTodayBookingsCount()
      fetchHolidays()
      fetchBookings()
      
      // Scroll to calendar section if coming from a booking link
      const element = document.getElementById('calendar-section')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    } catch (error) {
      console.error('Error during component mount:', error)
    }
  })

  // Update the generateTours function
  const generateTours = () => {
    const tourTimes = ['10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM']
    
    // Generate both types of tours for all time slots
    const allTours = tourTimes.flatMap((time, index) => [
      {
        id: index * 2,
        time,
        type: 'Tour and Tasting',
        isSelected: false,
        guests: null
      },
      {
        id: index * 2 + 1,
        time,
        type: 'Cellar Door Tasting',
        isSelected: false,
        guests: null
      }
    ])

    tours.value = allTours
  }

  // Make sure tours are generated initially
  generateTours()

  const selectTourTime = (time, tourId) => {
    const selectedTour = tours.value.find(tour => tour.id === tourId)
    
    // Track tour selection with Facebook Pixel
    trackSelectTourDateTime({
      tourId: tourId,
      tourType: selectedTour.type,
      date: format(selectedDate.value, 'yyyy-MM-dd'),
      time: time
    });
    
    // Track tour selection with Google Analytics
    if (window.gtag) {
      gtag('event', 'select_tour', {
        event_category: 'Tours',
        event_label: selectedTour.type,
        tour_type: selectedTour.type,  // Custom dimension
        time_slot: time,               // Custom dimension
        tour_id: tourId,
        value: selectedTour.type === 'Tour and Tasting' ? 4000 : 2500
      })
    }

    router.push({
      path: '/tours/confirm',
      query: {
        date: format(selectedDate.value, 'yyyy-MM-dd'),
        time: time,
        tourId: tourId,
        tourType: selectedTour.type
      }
    })
  }

  const upcomingHolidays = computed(() => {
    return holidays.value
      .filter(holiday => !isBefore(parseISO(holiday.date), startOfDay(new Date())))
      .sort((a, b) => parseISO(a.date) - parseISO(b.date))
      .slice(0, 10) // Show only next 10 holidays
  })

  // Add computed property for holiday check
  const isSelectedDateHoliday = computed(() => {
    const selectedDateStr = format(selectedDate.value, 'yyyy-MM-dd')
    return holidays.value.some(holiday => holiday.date === selectedDateStr)
  })

  const isOpenToday = computed(() => {
    const now = new Date()
    const todayStr = format(now, 'yyyy-MM-dd')
    const currentHour = now.getHours()
    const currentMinutes = now.getMinutes()
    const currentTime = currentHour + (currentMinutes / 60)
    
    // Check if it's Sunday or a holiday
    const isSunday = getDay(now) === 0
    const isHoliday = holidays.value.some(holiday => holiday.date === todayStr)
    
    // Check if current time is within business hours (8:00 AM - 5:00 PM)
    const isWithinBusinessHours = currentTime >= 8 && currentTime < 17
    
    return !isSunday && !isHoliday && isWithinBusinessHours
  })

  const closedReason = computed(() => {
    const now = new Date()
    const todayStr = format(now, 'yyyy-MM-dd')
    const currentHour = now.getHours()
    const currentMinutes = now.getMinutes()
    const currentTime = currentHour + (currentMinutes / 60)
    
    const isSunday = getDay(now) === 0
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const isTomorrowSunday = getDay(tomorrow) === 0
    const holiday = holidays.value.find(h => h.date === todayStr)
    
    if (isSunday) {
      return 'Closed on Sundays\nReopens Monday at 8:00 AM'
    }
    if (holiday) {
      return `Closed for ${holiday.name}\nReopens next business day at 8:00 AM`
    }
    if (currentTime < 8) {
      return 'Opens at 8:00 AM today'
    }
    if (currentTime >= 17) {
      return isTomorrowSunday 
        ? 'Closed for today\nReopens Monday at 8:00 AM'
        : 'Closed for today\nReopens tomorrow at 8:00 AM'
    }
    return 'Closed Today\nReopens next business day at 8:00 AM'
  })

  // Add the hardcoded 83 Islands Nights dates
  const islandsNightsDates = [
    '2025-01-31',
    '2025-02-28',
    '2025-03-28',
    '2025-04-25',
    '2025-05-30',
    '2025-06-27',
    '2025-07-25',
    '2025-08-29',
    '2025-09-26',
    '2025-10-31',
    '2025-11-28'
  ]

  const upcoming83IslandsNights = computed(() => {
    return islandsNightsDates
      .map(date => ({
        date,
        name: '83 Islands Night'
      }))
      .filter(event => !isBefore(parseISO(event.date), startOfDay(new Date())))
      .sort((a, b) => parseISO(a.date) - parseISO(b.date))
  })
</script>


<style>
/* Optional: Add smooth transition for header */
header {
    transition: transform 0.3s ease;
}

/* Add any additional styles here if needed */
.text-gold {
  color: #F6D993;
}

.bg-gold {
  background-color: #F6D993;
}

html {
  scroll-behavior: smooth;
}
</style>
