import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import router from './router'  // Import the router configuration
import './style.css'

// Create and mount the app
const app = createApp(App)
const head = createHead()

app.use(router)  // Use the router
app.use(head)
app.mount('#app')