<template>
  <header 
    v-show="showHeader" 
    :class="[
      'fixed top-0 left-0 right-0 z-30 transition-all duration-300',
      scrolled ? 'bg-black' : 'bg-transparent'
    ]"
  >
    <div class="container mx-auto flex justify-between items-center">
      <!-- Left-aligned SVG icon -->
      <router-link to="/">
        <img 
          src="/image/83-icon.svg" 
          alt="83 Icon" 
          class="w-16 h-16 [filter:brightness(0)_invert(1)]"
        />
      </router-link>

      <nav class="flex items-center space-x-3 lg:space-x-8 px-4 py-2">
        <router-link 
          v-for="(link, index) in navLinks" 
          :key="index"
          :to="link.to || '#'"
          active-class="text-gold"
          :class="[
            'font-maax text-xs lg:text-sm uppercase',
            inverted && !scrolled ? 'text-black hover:text-gray-700' : 'text-white hover:text-gray-300',
            link.mobileHidden ? 'hidden lg:inline' : ''
          ]"
        >
          {{ link.mobileText || link.text }}
        </router-link>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { useScroll } from '../composables/useScroll'

const props = defineProps({
  inverted: {
    type: Boolean,
    default: false
  }
});

const { scrolled, showHeader } = useScroll()

const navLinks = [
  { text: 'Discover', to: '/discover', mobileHidden: true },
  { text: 'The Spirits', to: '/spirits' },
  { text: 'Tours & Events', to: '/tours', mobileText: 'Tours' },
/*   { text: 'News', to: '/news', mobileHidden: true },
  { text: 'Community', to: '/community', mobileHidden: true }, */
  { text: 'Where to Buy', to: '/buy', mobileHidden: true },
  { text: 'Contact Us', to: '/contact', mobileHidden: true }
];
</script>